<template>
  <div class="tooltip">
    <img :src="`https://mc-heads.net/avatar/${name}.png`" class="tooltip-image">
    <span :class="'tooltiptext ' + position">
      <span class="minecraft-font">
        <span v-if="rank" :style="{color: rank.group.prefix.color}">{{ rank.group.prefix.name }} </span>
        <span>{{ name }}</span>
      </span>
      <br>
      <span>{{ dateToReadable(date) }}</span>
    </span>
  </div>
</template>

<script>
export default {
  name: "PlayerHead",
  props: {
    name: String,
    rank: Object,
    date: Number,
    position: String
  }, methods: {
    dateToReadable() {
      const d = new Date(this.date);

      return d.getDay() + "." + (d.getMonth()+1) + "." + d.getFullYear() + " " + d.getHours() + ":" + this.toTwo(d.getMinutes());
    },
    toTwo(i) {
      return (i < 10 ? "0" + i : i);
    }
  }
}
</script>

<style scoped>
.tooltip {
  padding: 5px;
  position: relative;
  display: inline-block;
}

.tooltip-image {
  height: 54px;
  width: 54px;
  border-radius: 8px
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 999;
}

.right {
  top: -25px;
  right: 50%;
}

.left {
  top: -25px;
  left: 50%;
}

@keyframes arrow {
  0% {transform: translateY(25%) }
  100% {transform: translateY(95%) }
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>
