<template>
  <div class="column is-3">
    <div class="box">
      <div class="container">
        <div class="columns is-mobile">
          <div class="column is-2 is-vertical-center">
            <figure class="image is-24x24">
              <b-skeleton v-if="!image" :animated="true"></b-skeleton>
              <img v-bind:src="image" v-else>
            </figure>
          </div>
          <div class="column is-9 is-vertical-center">
            <b-skeleton v-if="!title" :animated="true"></b-skeleton>
            <h1 class="title is-5" style="color: white;" v-else>{{ title }}</h1>
            <b-skeleton v-if="!stat" :animated="true"></b-skeleton>
            <h1 class="subtitle is-6 count"  style="color: white;" v-else-if="!time"><animated-number :value="stat" :round="1" :duration="1000"/>{{type}}</h1>
            <h1 class="subtitle is-6 count" style="color: white;" v-else><animated-number :value="timeObj.days" :round="1" :duration="1000"/> dni <animated-number :value="timeObj.hours" :round="1" :duration="1000"/> godzin <animated-number :value="timeObj.minutes" :round="1" :duration="1000"/> minut</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AnimatedNumber from "animated-number-vue";

import statsUtils from '@/statsUtils.js'

export default {
  name: "MainStatCard",
  props: {
    image: String,
    title: String,
    stat: Number,
    type: String,
    time: Boolean
  }, components: {AnimatedNumber},
  data() {
    return {
      timeObj: {days: 0, hours: 0, minutes: 0}
    }
  }, watch: {
    stat(newVal) {
      if(this.time) this.timeObj = statsUtils.readableTimeObject(newVal);
    }
  }, created() {
    if(this.time) this.timeObj = statsUtils.readableTimeObject(this.stat);
  }
}
</script>

<style scoped>
.is-vertical-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
