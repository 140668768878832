<template>
  <nav class="navbar is-mobile" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <a role="button" class="navbar-burger" v-on:click="active = !active" style="color: white;">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>


    <div id="navbarBasicExample" v-bind:class="'navbar-menu' + (active ? ' is-active' : '') + (true ? ' white' : ' dark')">
      <div class="navbar-start">
        <router-link class="navbar-item" style="font-size: 30px; font-weight: 700; display: flex; justify-content: center; align-items: center;" to="/">
          <img src="/kaczkoland.jpg" style="height: 60px; border-radius: 4px">
          <span style="margin-left: 15px; line-height: 0.6; display: flex;">
                    <span :class="(blackNav ? ' black' : ' white')" style="font-weight: 700; font-size: 1.5rem;">Kaczkoland</span>
                    <span :class="(blackNav ? ' black' : ' white')" style="font-weight: 600; font-size: 1.5rem;">.pl</span>
                    <br>
<!--                    <span style="color: white; font-weight: 400; font-size: 1rem;">Serwer Minecraft</span>-->
                  </span>
          <!--                  <div style="margin-left: 15px;">-->
          <!--                    <span style="color: #639fff; font-weight: 700">Kaczkoland</span><span style="color: #639fff; font-weight: 400;">.pl<br>aaa</span>-->
          <!--                  </div>-->
        </router-link>
        <router-link :class="'navbar-item' + (blackNav ? ' black' : ' white')" style="font-size: 1.3rem;" to="/statystyki">Statystyki</router-link>
        <router-link :class="'navbar-item' + (blackNav ? ' black' : ' white')" style="font-size: 1.3rem;" to="/kary">Kary</router-link>
        <router-link :class="'navbar-item' + (blackNav ? ' black' : ' white')" style=" font-size: 1.3rem;" to="/partner">Partnerstwo</router-link>
        <router-link :class="'navbar-item' + (blackNav ? ' black' : ' white')" style="font-size: 1.3rem;" to="/o-nas">O nas</router-link>
        <a :class="'navbar-item' + (blackNav ? ' black' : ' white')" style="font-size: 1.3rem;" onclick="window.open('https://docs.kaczkoland.pl')">Pomoc</a>
        <router-link to="/regulamin" :class="'navbar-item' + (blackNav ? ' black' : ' white')" style="font-size: 1.3rem;">Regulamin</router-link>
<!--        <a :class="'navbar-item' + (blackNav ? ' black' : ' white')" style="font-size: 1.3rem;" onclick="window.open('https://sklep.kaczkoland.pl'); plausible('Sklep');">Sklep</a> -->
        <a :class="'navbar-item' + (blackNav ? ' black' : ' white')" style="font-weight: bold; font-size: 1.3rem;" onclick="window.open('https://discord.kaczkoland.pl'); plausible('Discord');">Discord</a>
      </div>
      <div class="navbar-end">
        <div class="navbar-item" v-if="online !== -1">
          <span style="color: #ff9b00; font-weight: 600; font-size: 1.15rem;">{{ online }} GRACZY ONLINE</span>
        </div>
        <div class="navbar-item">
          <div class="buttons">
            <button class="button is-light pulse" style="background-color: #639fff; color: white; border-radius: 32px; padding: 2px 28px; font-weight: 700;" to="/login">
              Zaloguj się
            </button>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import axios from "axios";

export default {
  name: "Navbar",
  data() {
    return {
      online: -1,
      active: false
    }
  },
  created() {
    axios.get("https://api.mcsrvstat.us/2/kaczkoland.pl").then(response => {
      this.online = response.data.players.online;
    })
  }, props: {
    blackNav: {
      default: false
    }
  }
}
</script>

<style>
.dark {
  color: #141414 !important;
}

.white {
  color: #fff !important;
}
</style>
