<template>
  <section class="section" style="padding-bottom: 50px" v-if="Array.isArray(apps) || apps.length > 0">
    <div class="container has-text-centered">
      <h1 class="title">Aplikacje partnerskie Kaczkolandu</h1>
      <h2 class="subtitle is-5">Wymienione poniżej aplikacje posiadają funkcję do sprawdzania statystyk gracza na serwerze i inne.</h2>
    </div>
    <br>
    <div class="container">
      <div class="columns is-centered is-multiline">
        <Card v-for="app in apps" :key="app" v-bind:data="app"></Card>
      </div>
    </div>
    <br>
    <p class="has-text-centered" style="color: white;">Chcesz, aby Twój bot Discord/aplikacja/strona tutaj była? <a href="https://api-docs.kaczkoland.pl" target="_blank">Zobacz nasze API</a><br>Boty Discord wymienione wyżej mogą nie być zarządzane przez nas, nie odpowiadamy za ewentualne zniszczenia serwera. Najbezpieczniej jest nie dawać botowi uprawnień do wyrzucania, banowania, usuwania kanałów itd.</p>
  </section>
</template>

<script>
import Card from "@/components/Card";
import axios from "axios";

export default {
  name: "Apps",
  components: { Card },
  created() {
    axios.get("apps.json").then(result => this.apps = result.data);
  }, data: function() {
    return {
      apps: []
    }
  }
}
</script>

<style scoped>

</style>
