import Vue from 'vue'
import VueRouter from 'vue-router'
import Buefy from 'buefy'
import Home from '../views/Home.vue'
import IndexHero from "@/layout/IndexHero";
import Footer from "@/layout/Footer";
// import User from "@/layout/User";
import SecondHero from "@/layout/SecondHero";
import Rules from "@/views/Rules";
import Faq from "@/views/Faq";
import Stats from "@/views/Stats";
import About from "@/views/About";
import Start from "@/views/Start";
// import Kary from "@/views/Kary";
import Partner from "@/views/Partner";
import Header from "@/components/Header";
import UserCardNew from "@/components/UserCardNew";
import Navbar from "@/layout/Navbar";
import KaryDisabled from "@/views/KaryDisabled";
import User from "@/layout/User";
// import StatsDisabled from "@/views/StatsDisabled";
// import Test from "@/views/Test";

Vue.use(VueRouter)
Vue.use(Buefy, {
  defaultIconPack: 'fas',
  defaultContainerElement: '#content'
})

const routes = [
  {
    path: '/',
    name: 'Dom',
    components: {
      header: IndexHero,
      default: Home,
      footer: Footer
    },
    meta: { blackNav: true }
  },
  {
    path: '/statystyki',
    name: 'Statystyki',
    components: {
      header: Navbar,
      default: Stats,
      footer: Footer
    }
  },
  {
    path: '/regulamin',
    name: 'Regulamin',
    components: {
      header: Navbar,
      default: Rules,
      footer: Footer
    }
  },
  {
    path: '/faq',
    name: 'FAQ',
    components: {
      header: SecondHero,
      default: Faq,
      footer: Footer
    }
  },
  {
    path: '/kary',
    name: 'Kary',
    components: {
      header: Navbar,
      default: KaryDisabled,
      footer: Footer
    }
  },
  {
    path: '/o-nas',
    name: 'O nas',
    components: {
      header: Navbar,
      default: About,
      footer: Footer
    }
  },
  {
    path: '/u/:id',
    name: 'Statystyki',
    components: {
      header: User,
      footer: Footer
    }
  },
  {
    path: '/start',
    name: 'Start',
    components: {
      header: SecondHero,
      default: Start,
      footer: Footer
    }
  },
  {
    path: '/partner',
    name: 'Partnerstwo',
    components: {
      header: Navbar,
      default: Partner,
      footer: Footer
    }
  },
  {path: "/u", alias: '/user', beforeEnter() {location.href = "/statystyki"}},
  {path: '/user/:id', redirect: "/u/:id"},
  {path: '/stats', alias: ["/statistics"], beforeEnter() {location.href = "/statystyki"}},
  {path: '/staff', alias: "/administracja", beforeEnter() {location.href = "/o-nas"}},
  {path: '/login', alias: "/login", beforeEnter() {location.href = "/statystyki"}},
  {path: '/bany', beforeEnter() {location.href = "/kary"}},
  //Social Media
  {path: "/twitter", beforeEnter() {location.href = "https://twitter.com/kaczkoland"}},
  {path: "/instagram", beforeEnter() {location.href = "https://instagram.com/kaczkoland"}},
  {path: "/discord", beforeEnter() {location.href = "https://discord.com/invite/MfH5qN4"}},
  {path: "/youtube", beforeEnter() {location.href = "https://www.youtube.com/channel/UCzdQCovvqG1EdCUPe3puN5Q"}},
  {path: "/facebook", beforeEnter() {location.href = "https://facebook.com/kaczkoland"}},
  {path: "/twitch", beforeEnter() {location.href = "https://twitch.tv/kaczkoland"}},
  {path: "/tiktok", beforeEnter() {location.href = "https://tiktok.com/@kaczkoland"}},
  {path: "/rekrutacja", beforeEnter() {location.href = "https://tally.so/r/mD4bRb"}},
  {path: "/ankieta", beforeEnter() {location.href = "https://forms.gle/RpQKXjZa2CMJMLj58"}},
  {path: "/wesprzyj", beforeEnter() {location.href = "https://tipo.live/p/kaczkoland"}},
  {path: "/event-mikolajki", beforeEnter() {location.href = "https://tally.so/r/wLDdEJ"}},
  //404
  {path: "*", redirect: "/"},
  {path: "/new", component: Header},
  {path: "/new-user", component: UserCardNew}
]

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    return {x: 0, y: 0}
  }
})

export default router
