<template>
  <div>
    <b-skeleton count="5" width="54px" height="54px" :animated="true"></b-skeleton>
  </div>
</template>

<script>
export default {
  name: "PlayerHead"
}
</script>

<style scoped>
.b-skeleton {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.b-skeleton-item {
  margin-top: 0 !important;
  margin-left: 5px;
}
</style>
