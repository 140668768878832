<template>
  <div class="section hero is-info is-fullheight" style="background-color: #3b3939;">
    <div class="hero-head">
      <navbar></navbar>
    </div>
    <div class="hero-body">
      <div class="container has-text-centered">
        <div class="columns is-centered">
          <div class="column is-8">
            <user-card-new></user-card-new>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/layout/Navbar";
// import UserCard from "@/components/UserCard";
import UserCardNew from "@/components/UserCardNew";
export default {
  name: "User",
  components: {UserCardNew,Navbar}
}
</script>

<style scoped>
.hero.section {
  padding: 0;
}

.header-image::before{
  content:"";
  background: url(/background.jpg);
  background-size:cover;
  position:absolute;
  top:0;
  right:0;
  bottom:0;
  left:0;
  opacity:.40
}
</style>
