import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL;

export async function getSumData() {
    const response = await axios.get(API_URL + "/v2/getSumData").catch(handleError);

    return response.data;
}

export async function getTopPlayers() {
    const response = await axios.get(API_URL + "/v2/getTopPlayers").catch(handleError);

    return response.data;
}

export async function getPlayers(player) {
    const response = await axios.get(API_URL + "/v2/getPlayers?player=" + player).catch(handleError);

    return response.data;
}

export async function getPlayer(player) {
    const response = await axios.get(API_URL + "/v2/u/" + player).catch(handleError);

    return response.data;
}

// export async function getPunishments() {
//     const response = await axios.get(API_URL + "/getPunishments").catch(handleError);
//
//     return response.data;
// }


function handleError() {
    return {success: false}
}
