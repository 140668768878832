<template>
  <div>
    <a href="/twitter"  target="_blank" class="sm">
                                <span class="icon clickable">
                                    <i class="fab fa-twitter" style="font-size: 1.75em; color: white;"></i>
                                </span>
    </a>
    <a href="/instagram" target="_blank" class="sm">
                                <span class="icon clickable">
                                    <i class="fab fa-instagram" style="font-size: 1.75em; color: white;"></i>
                                </span>
    </a>
    <a href="/facebook" target="_blank" class="sm">
                                <span class="icon clickable">
                                    <i class="fab fa-facebook" style="font-size: 1.75em; color: white;"></i>
                                </span>
    </a>
    <a href="/youtube" target="_blank" class="sm">
                                <span class="icon clickable">
                                    <i class="fab fa-youtube" style="font-size: 1.75em; color: white;"></i>
                                </span>
    </a>
    <a href="/discord" target="_blank" class="sm">
                                <span class="icon clickable">
                                    <i class="fab fa-discord" style="font-size: 1.75em; color: white;"></i>
                                </span>
    </a>
    <a href="/twitch" target="_blank" class="sm">
                  <span class="icon clickable">
                      <i class="fab fa-twitch" style="font-size: 1.75em; color: white;"></i>
                  </span>
    </a>
    <a href="/tiktok" target="_blank" class="sm">
                  <span class="icon clickable">
                      <i class="fab fa-tiktok" style="font-size: 1.75em; color: white;"></i>
                  </span>
    </a>
  </div>
</template>

<script>
export default {
name: "SocialMedia"
}
</script>

<style scoped>
.sm {
  font-size: 1em;
  margin: 8px;
  color: black;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
