<template>
  <footer class="footer">
    <div class="container has-text-centered">
      <div class="columns is-centered is-multiline">
        <div class="column is-3-desktop is-half-tablet is-vertical-center">
          <h1 class="title is-5" style="margin: 5px;">Kaczkoland.pl</h1>
          <h2 class="subtitle is-6" style="margin: 5px;">Serwer Minecraft survival</h2>
          <div class="mt-3">
            <SocialMedia></SocialMedia>
          </div>
        </div>
        <div class="column is-3-desktop is-half-tablet">
          <h2 class="title is-5" style="margin-bottom: 5px">Serwer Minecraft</h2>
          <ul>
            <li><router-link to="/statystyki">Statystyki</router-link></li>
            <li><router-link to="/regulamin">Regulamin</router-link></li>
            <li><router-link to="/kary">Kary</router-link></li>
            <li><a href="https://sklep.kaczkoland.pl/rangi" target="_blank">Rangi</a></li>
          </ul>
        </div>
        <div class="column is-3-desktop is-half-tablet">
          <h2 class="title is-5" style="margin-bottom: 5px">Inne</h2>
          <ul>
            <li><router-link to="/o-nas">O nas</router-link></li>
            <li><router-link to="/regulamin">Regulamin</router-link></li>
            <li><router-link to="/faq">Pytania i odpowiedzi</router-link></li>
          </ul>
        </div>
        <div class="column is-3-desktop is-half-tablet">
          <h2 class="title is-5" style="margin-bottom: 5px">Więcej</h2>
          <ul>
            <li><a href="https://status.kaczkoland.pl" rel="noopener" target="_blank">Status serwisów</a></li>
            <!-- <li><a href="https://sklep.kaczkoland.pl" rel="noopener" target="_blank">Sklep z rangami</a></li> -->
            <li><a href="https://discord.kaczkoland.pl" rel="noopener" target="_blank">Serwer Discord</a></li>
            <li><a href="https://blog.kaczkoland.pl" rel="noopener" target="_blank">Blog</a></li>
            <li><router-link to="/rekrutacja" target="_blank">Rekrutacja</router-link></li>
          </ul>
        </div>
      </div>
      <hr style="border: 1px solid gray;">
    </div>
    <div class="content has-text-centered" style="color: white">
      <p>
        Strona stworzona przez <a rel="noopener" href="https://rkuba.pl" target="_blank">rkubapl</a> używając <a rel="noopener" href="https://buefy.org/" target="_blank">Buefy</a>, <a rel="noopener" href="https://vuejs.org" target="_blank">VueJS</a>, <a rel="noopener" href="https://fontawesome.com" target="_blank">FontAwesome</a>, <a rel="noopener" href="https://api.mcsrvstat.us" target="_blank">mcsrvstat.us</a>, <a rel="noopener" href="https://wikiki.github.io/components/timeline/" target="_blank">wikiki</a>, <a href="https://www.onlinewebfonts.com/download/6ab539c6fc2b21ff0b149b3d06d7f97c" target="_blank" rel="noopener">czcionki z Minecraft</a>, <a href="https://fonts.google.com/specimen/Poppins" target="_blank" rel="noopener">czcionki Poppins</a>, <a href="https://fonts.google.com/specimen/Open+Sans" target="_blank" rel="noopener">czcionki Open Sans</a> oraz <a rel="noopener" href="https://github.com/axios/axios" target="_blank">axios.io</a>.<br> Zdjęcia itemów MC pochodzą ze strony <a rel="noopener" href="https://minecraft-pl.gamepedia.com/Strona_g%C5%82%C3%B3wna" target="_blank">minecraft-pl.gamepedia.com</a>.
      </p>
    </div>
  </footer>
</template>

<script>
import SocialMedia from "@/components/SocialMedia";
export default {
  name: "Footer",
  components: {SocialMedia}
}
</script>

<style scoped>
footer {
  background-color: rgb(50, 50, 50);
}
li{
  margin: 10px 0;
}

ol ol {
  margin-left: 2em;
}

</style>
