<template>
  <div class="column is-4 is-4-desktop is-6-tablet clickable">
<!--    <div class="box">-->
      <div class="container has-text-centered" style="margin-bottom: 20px;">
        <h2 class="title" v-bind:style="'font-weight: 550; font-size: 1.55rem; color:' + color">Topka {{ type }}</h2>
      </div>
      <div class="box" v-for="(item, id) in data" :key="id" @click="redirect_user(item)">
          <b-skeleton v-if="!item" :animated="true"></b-skeleton>
          <h1 v-else class="title position">{{ id + 1 }}.</h1>

          <img v-if="item" class="clickable avatar" @click="redirect(item)" v-bind:src="'https://mc-heads.net/avatar/' + item.player.name +  '.png'">

          <span v-if="item"  class="minecraft-font px nickname"><span v-if="item.player.rank !== undefined" v-bind:style="{color: item.player.rank.group.prefix.color}" >{{item.player.rank.group.prefix.name}}</span> <span style="color: white; padding-left: 2px">{{ item.player.name }}</span></span>

          <h1 class="title is-4 is-right value" v-if="item">{{ time ? readableShortTime(item.value) : item.value }}{{ unit }}</h1>
      </div>
  </div>
</template>

<script>
import statsUtils from "@/statsUtils";

export default {
  name: "Stat",
  props: {
    type: String,
    data: {
      type: Array,
      default: function () {
        return ['', '', '', '', '']
      }
    },
    name: String,
    unit: String,
    color: String,
    time: Boolean
  }, methods: {
    redirect(item) {
      this.$router.push('/u/' + item);
    },
    redirect_user(item) {
      this.redirect(item.player.name);
    },
    readableShortTime: statsUtils.readableShortTime
    // open(url) { window.open(url); }
  }
}
</script>

<style scoped>
.is-vertical-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.topka {
  border-top: 1.5px solid gray;
}
.clickable{
  cursor:pointer;
  outline:none
}

.px {
     font-size: 14px;
}

.position {
  display: inline-block;
  vertical-align:middle;
  margin-bottom: 0;
  margin-right: 10px;
  color: white;
  font-size: 1.75rem;
}

.avatar {
  display: inline-block;
  vertical-align:middle;
  width: 50px;
  height: 50px;
  margin-right: 20px;
}

.nickname {
  color: black;
  display: inline-block;
  vertical-align:middle;
  margin-bottom: 0;
  margin-right: 7px;
  font-weight: 800;
  font-size: 1rem;
}

.value {
  color: white;
  display: inline-block;
  vertical-align:middle;
  position: relative;
  float: right;
  top: 10px;
  font-weight: 200;
}
</style>
