<template>
  <div>
    <section class="hero is-fullheight">
      <div class="hero-head">
<!--        <div class="container">-->
        <Navbar></Navbar>
<!--        </div>-->
      </div>
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-2 has-text-centered" style="color: #141414;">KACZKOLAND</h1>
          <h1 class="subtitle is-4 has-text-centered has-text-weight-medium" style="color: white; margin-bottom: 5px;">Twój serwer Minecraft survival.</h1>
          <div class="buttons is-centered" style="margin-top: 10px;">
            <a class="button is-primary is-normal" style="border-radius: 8px; font-size: 1.1rem;">
              Dołącz
            </a>
            <a class="button is-primary is-outlined" style="border-radius: 8px; font-size: 1.1rem;">
              Dowiedz się więcej
            </a>
          </div>
<!--          <div class="columns is-vcentered">-->
<!--            <div class="column is-half">-->
<!--              <h1 class="title is-2 has-text-centered" style="color: white">KACZKOLAND</h1>-->
<!--              <h1 class="subtitle is-4 has-text-centered" style="color: white; margin-bottom: 5px;">Serwer Survival na najnowszą wersję gry</h1>-->
<!--              <div class="buttons is-centered">-->
<!--                <a class="button is-primary is-normal" style="border-radius: 8px; font-size: 1.1rem;">-->
<!--                  Dołącz do nas-->
<!--                </a>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="column is-half">-->
<!--              <center>-->
<!--                <figure class="image is-256x256 float">-->
<!--                  <img src="/kaczkoland.jpg">-->
<!--                </figure>-->
<!--              </center>-->
<!--            </div>-->

<!--          </div>-->
        </div>
      </div>
      <div class="hero-foot arrow" style="margin: 50px;">
        <div class="container has-text-centered clickable" onclick="document.getElementById('welcome').scrollIntoView({behavior: 'smooth'})">
          <span class="icon arrow">
            <i class="fas fa-chevron-down" style="font-size: 2em;"></i>
          </span>
        </div>
      </div>
    </section>
    <section class="section" style="background-color: #3b3939; margin-bottom: 0">
      <div class="container has-text-centered" style="margin-bottom: 20px;">
        <h1 class="title" style="color: white;">Witaj na Kaczkolandzie!</h1>
        <h1 class="subtitle" style="color: white;">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam enim ligula, egestas a molestie sit amet, placerat quis dui. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi condimentum lectus lectus, ut volutpat lacus ullamcorper vitae. Vivamus maximus magna sit amet sapien scelerisque bibendum. Nunc at nibh mi. Aenean convallis nisl metus, nec rhoncus erat finibus in. Fusce metus nibh, luctus vel tristique quis, tristique in sapien.</h1>
      </div>
      <div class="container has-text-centered">
        <div class="columns is-multiline is-centered">
          <div class="column is-3" style="padding-top: 24px;">
                            <span class="icon" style="font-size: 3.5em; color: white">
                                <i class="fas fa-server"></i>
                            </span>
            <h1 class="title is-4 has-text-centered" style="color: white;">Stabilna rozgrywka</h1>
            <h2 class="subtitle is-6" style="color: white">Staramy się, aby serwer działał jak <span class="underline">najdłużej</span> i <span class="underline">stablinie</span>!</h2>
          </div>
          <div class="column is-3" style="padding-top: 24px;">
                            <span class="icon" style="font-size: 3.5em; color: white">
                                <i class="fas fa-money-bill"></i>
                            </span>
            <h1 class="title is-4 has-text-centered" style="color: white;">Brak dużej przewagi rang</h1>
            <h2 class="subtitle is-6" style="color: white">Rangi <span class="underline">nie mają dużej przewagi</span> na serwerze, są one bardziej kosmetyczne.</h2>
          </div>
          <div class="column is-3" style="padding-top: 24px;">
                            <span class="icon" style="font-size: 3.5em; color: white">
                                <i class="fas fa-user-friends"></i>
                            </span>
            <h1 class="title is-4" style="color: white">Non-premium</h1>
            <h2 class="subtitle is-6" style="color: white">Nie musisz posiadać oryginalnego Minecrafta, aby grać u nas na serwerze!</h2>
          </div>
<!--          <div class="column is-3" style="padding-top: 24px;">-->
<!--                            <span class="icon" style="font-size: 3.5em; color: white">-->
<!--                                <i class="fas fa-external-link-alt"></i>-->
<!--                            </span>-->
<!--            <h1 class="title is-4" style="color: white">Najnowsza wersja gry</h1>-->
<!--            <h2 class="subtitle is-6" style="color: white">Nasz serwer działa na <span class="underline">najnowszej wersji</span> Minecraft! Dzięki temu będziesz aktualny z <span class="und-->
<!--">najnowszymi aktualizacjami Minecrafta</span>!</h2>-->
<!--          </div>-->
          <div class="column is-3" style="padding-top: 24px;">
                            <span class="icon" style="font-size: 3.5em; color: white">
                                <i class="fas fa-level-up-alt"></i>
                            </span>
            <h1 class="title is-4" style="margin-top: 20px; color: white">Śledź swoje statystyki</h1>
            <h2 class="subtitle is-6" style="color: white">Na naszej <span class="underline">stronie internetowej</span> możesz <b>wyświeltać</b> swoje lub innych graczy <b>statystyki</b>.</h2>
<!--            <a class="button is-danger" rel="noopener" href="/statystyki" target="_blank">Zobacz statystyki</a>-->
          </div>
          <div class="column is-3" style="padding-top: 24px;">
                            <span class="icon" style="font-size: 3.5em; color: white">
                                <i class="fab fa-discord"></i>
                            </span>
            <h1 class="title is-4" style="margin-top: 20px; color: white">Serwer Discord</h1>
            <h2 class="subtitle is-6" style="color: white">Posidamy serwer Discord, na którym możesz <span class="underline">porozmawiać</span> z innymi graczami lub <span class="underline">rozwiązać jakiś problem</span>.</h2>
<!--            <a class="button is-info" rel="noopener" href="https://discord.kaczkoland.pl" target="_blank">Dołącz już teraz</a>-->
          </div>
          <div class="column is-3" style="padding-top: 24px;">
                            <span class="icon" style="font-size: 3.5em; color: white">
                                <i class="fas fa-calendar-week"></i>
                            </span>
            <h1 class="title is-4" style="margin-top: 10px; color: white;">Różne konkursy</h1>
            <h2 class="subtitle is-6" style="color: white">Organizujemy <b>różne konkursy</b>. Jeśli nie jesteś dobry w jednej konkurencji możesz wziąść udział w innej!</h2>
<!--            <a class="button is-success" rel="noopener" href="https://blog.kaczkoland.pl/tag/eventy/" target="_blank">Zobacz konkursy</a>-->
          </div>
        </div>
      </div>
    </section>
    <section class="section" style="background-color: #3b3939; margin-bottom: 0">
      <div class="container has-text-centered" style="margin-bottom: 20px;">
        <h1 class="title" style="color: white; margin-bottom: 30px">Tryby na Kaczkolandzie</h1>
      </div>
      <div class="container">
        <div class="columns is-vcentered">
          <div class="column is-half">
            <img src="/images/survival.png" style="border-radius: 16px;">
          </div>
          <div class="column is-half">
            <h1 class="title has-text-centered" style="color: white">Survival + działki (1.17.1)</h1>
            <h2 class="subtitle has-text-centered" style="color: white">dupa </h2>
          </div>
        </div>
        <div class="columns is-vcentered reverse-columns">
          <div class="column is-half">
            <h1 class="title has-text-centered" style="color: white">Berek</h1>
            <h2 class="subtitle has-text-centered" style="color: white">Minigra, w której udział bierze od 2 do 6 graczy. Jedna osoba staje się osobą goniącą i jej celem jest przekazanie swojej roli goniącego innemu graczowi poprzez uderzenie lub strzał łuku. Po 60 sekundach osoba goniąca odpada i rozpoczyna się kolejna runda. Wygrywa osoba, która w ostatniej rundzie (gdy zostanie 2 graczy) na końcu czasu nie będzie osobą goniącą.</h2>
          </div>
          <div class="column is-half">
            <img src="/images/berek.jpg" style="border-radius: 16px;">
          </div>
        </div>
      </div>
    </section>
      <div style="background-color: #3b3939;">
        <svg style="display: block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#f3f4f5" fill-opacity="1" d="M0,224L48,229.3C96,235,192,245,288,245.3C384,245,480,235,576,213.3C672,192,768,160,864,176C960,192,1056,256,1152,266.7C1248,277,1344,235,1392,213.3L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
      </div>
      <section class="section" style="background-color: #f3f4f5;  margin-top: 0; padding-top: 0">
        <div class="container has-text-centered" style="margin-bottom: 20px;">
          <h1 class="title">Statystyki</h1>
        </div>
        <div class="container">
            <div class="columns">
              <div class="column is-half">
                <h1 class="subtitle has-text-centered">Najwięcej zabitych graczy (Survival)</h1>
                <div class="box">
                      <h1 class="title" style="display: inline-block; vertical-align:middle; margin-bottom: 0; margin-right: 10px;">1.</h1>
                      <img src="/kaczkoland.jpg" style="display: inline-block; vertical-align:middle; width: 50px; height: 50px; margin-right: 20px;">
                      <h1 class="subtitle is-4" style="display: inline-block; vertical-align:middle; margin-bottom: 0; margin-right: 10px;">rkubapl</h1>
                      <h1 class="title is-4 is-right" style="display: inline-block; vertical-align:middle; position: relative; float: right; top: 30px;">100</h1>
                </div>
                <div class="box">
                  <h1 class="title" style="display: inline-block; vertical-align:middle; margin-bottom: 0; margin-right: 10px;">2.</h1>
                  <img src="/kaczkoland.jpg" style="display: inline-block; vertical-align:middle; width: 50px; height: 50px; margin-right: 20px;">
                  <h1 class="subtitle is-4" style="display: inline-block; vertical-align:middle; margin-bottom: 0; margin-right: 10px;">rkubapl</h1>
                  <h1 class="title is-4 is-right" style="display: inline-block; vertical-align:middle; position: relative; float: right; top: 30px;">100</h1>
                </div>
              </div>
              <div class="column is-half">
                <h1 class="subtitle has-text-centered">Najwięcej wygranych gier (Berek)</h1>
                <div class="box">
                  <h1 class="title" style="display: inline-block; vertical-align:middle; margin-bottom: 0; margin-right: 10px;">1.</h1>
                  <img src="/images/img.jpg" style="display: inline-block; vertical-align:middle; width: 50px; height: 50px; margin-right: 20px;">
                  <h1 class="subtitle is-4" style="display: inline-block; vertical-align:middle; margin-bottom: 0; margin-right: 10px;">rkubapl</h1>
                  <h1 class="title is-4 is-right" style="display: inline-block; vertical-align:middle; position: relative; float: right; top: 30px;">100</h1>
                </div>
                <div class="box">
                  <h1 class="title" style="display: inline-block; vertical-align:middle; margin-bottom: 0; margin-right: 10px;">2.</h1>
                  <img src="/i.jpg" style="display: inline-block; vertical-align:middle; width: 50px; height: 50px; margin-right: 20px;">
                  <h1 class="subtitle is-4" style="display: inline-block; vertical-align:middle; margin-bottom: 0; margin-right: 10px;">rkubapl</h1>
                  <h1 class="title is-4 is-right" style="display: inline-block; vertical-align:middle; position: relative; float: right; top: 30px;">100</h1>
                </div>
              </div>
            </div>
            <div class="buttons is-centered">
              <a class="button is-primary is-medium">Zobacz więcej</a>
            </div>
        </div>
      </section>
      <div style="background-color: #3b3939;">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#f3f4f5" fill-opacity="1" d="M0,224L60,234.7C120,245,240,267,360,261.3C480,256,600,224,720,202.7C840,181,960,171,1080,170.7C1200,171,1320,181,1380,186.7L1440,192L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"></path></svg>
      </div>
    <section class="section" style="background-color: #3b3939;">
      <div class="container has-text-centered" style="margin-bottom: 25px;">
        <h1 class="title" style="color: white">Ostatnie posty na blogu</h1>
      </div>
      <div class="container">
        <div class="columns">
          <div class="column is-4" v-for="post in posts.slice(0,3)" :key="post">
            <div class="card">
              <div class="card-image">
                <figure class="image is-4by3" v-if="post.feature_image">
                  <img v-bind:src="post.feature_image" alt="Placeholder image">
                </figure>
              </div>
              <div class="card-content">
                <div class="media">
                  <div class="media-content">
                    <p class="title is-4">{{ post.title }}</p>
                    <p class="subtitle is-6">{{ post.excerpt }}</p>
                  </div>
                </div>
              </div>
              <footer class="card-footer">
                <a rel="noopener" target="_blank" class="card-footer-item" v-bind:href="post.url">Zobacz post</a>
              </footer>
            </div>
          </div>
        </div>
        <div class="buttons is-centered">
          <a class="button is-primary" href="https://blog.kaczkoland.pl" target="_blank" rel="noopener">Zobacz więcej</a>
        </div>
      </div>
    </section>
    </div>
</template>

<script>
import axios from "axios";
import Navbar from "@/layout/Navbar";

export default {
  name: "Header",
  components: {
    Navbar
  },
  data() {
    return {
      blogKey: "25c3f3833276b0c895b2dea897",
      posts: [],
    }
  }, created() {
    axios.get("https://blog.kaczkoland.pl/ghost/api/v3/content/posts/?key=" + this.blogKey).then(results => {
      this.posts = results.data.posts;
    })
  }, methods: {
    open(url) { window.open(url); }
  }
}
</script>

<style scoped>
.player-card {
  padding: 5px 5px;
  background-color: #4a4a4a;
}
.hero {
  background-color: #3b3939;
}
.is-vcentered {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.is-256x256 {
  height: 256px;
  width: 256px;
}

@keyframes float {
  0% {
    /* box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6); */
    transform: translatey(20px);
  }
  50% {
    /* box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2); */
    transform: translatey(-20px);
  }
  100% {
    /* box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6); */
    transform: translatey(20px);
  }
}

.float {
  transform: translatey(0px);
  animation: float 6s ease-in-out infinite;
}

html {
  box-sizing: border-box;
}
*,*::before,*::after {
  box-sizing: inherit;
}
body {
  margin: 0;
}

.underline {
  /*text-decoration: none;*/
  /*border-bottom: 1px solid #639fff;*/
  /*text-decoration-color: #639fff;*/
  font-weight: bold;
}

.arrow {
  animation: arrow 2s infinite;
  color: white;
}


@keyframes arrow {
  0% {transform: translateY(25%) }
  100% {transform: translateY(95%) }
}
</style>
