<template>
  <section class="section">
    <div class="container">
      <h1 class="title has-text-centered">Pytania i odpowiedzi</h1>
      <h1 class="subtitle has-text-centered">Masz problem? Znajdź rozwiązanie twojego problemu.</h1>
    </div>
    <br>
    <div class="container">
      <div v-for="s in questions" :key="s">
        <h1 class="title has-text-centered" v-if="s.type">{{s.type}}</h1>
        <h1 class="title has-text-centered" v-else><b-skeleton :animated="true" position="is-centered" height="25" width="50%"></b-skeleton></h1>
        <FaqCard v-for="q in s.questions" :key="q" v-bind:question="q.question" v-bind:answer="q.answer"></FaqCard>
        <br>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import FaqCard from "@/components/FaqCard";

export default {
  name: "Faq",
  components: {FaqCard},
  data : function () {
    return {
      questions: [
        {
          questions: [
            {},
            {},
            {}
          ]
        },
        {
          questions: [
            {},
            {}
          ]
        }
      ],
      error: false
    }
  }, created() {
    axios.get("/faq.json").then(response => {
      this.questions = response.data;
    }).catch(() => this.error = true);
  }, watch: {
    error : function (error) {
      if(error) {
        this.$buefy.snackbar.open({
          message: 'Wystąpił błąd podczas ładowania danych',
          type: 'is-danger',
          position: 'is-bottom',
          actionText: 'Przeładuj stronę',
          indefinite: true,
          onAction: () => {
            this.$router.go(this.$router.currentRoute)
          }
        })
      }
    }
  }
}
</script>

<style scoped>

</style>