<template>
  <section class="hero is-fullheight" style="background-image: url('/bg.png');   background-repeat:no-repeat;
  background-size: cover;
  background-position: center; ">
    <div class="hero-head">
      <!--        <div class="container">-->
      <Navbar :black-nav="true"></Navbar>
      <!--        </div>-->
    </div>
    <div class="hero-body">
      <div class="container">
        <h1 class="title is-2 has-text-centered" style="color: #141414;">Kaczkoland.pl</h1>
        <h1 class="subtitle is-4 has-text-centered has-text-weight-semibold" style="color: #141414; margin-bottom: 5px;">Twój serwer Minecraft survival</h1>
        <div class="buttons is-centered" style="margin-top: 10px;">
          <a class="button is-primary is-normal" style="border-radius: 8px; font-size: 1.1rem;" v-on:click="isActive = true">
            Dołącz
          </a>
          <a class="button is-secondary" style="border-radius: 8px; font-size: 1.1rem;" onclick="document.getElementById('welcome').scrollIntoView({behavior: 'smooth'})">
            Dowiedz się więcej
          </a>
        </div>
        <!--          <div class="columns is-vcentered">-->
        <!--            <div class="column is-half">-->
        <!--              <h1 class="title is-2 has-text-centered" style="color: white">KACZKOLAND</h1>-->
        <!--              <h1 class="subtitle is-4 has-text-centered" style="color: white; margin-bottom: 5px;">Serwer Survival na najnowszą wersję gry</h1>-->
        <!--              <div class="buttons is-centered">-->
        <!--                <a class="button is-primary is-normal" style="border-radius: 8px; font-size: 1.1rem;">-->
        <!--                  Dołącz do nas-->
        <!--                </a>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--            <div class="column is-half">-->
        <!--              <center>-->
        <!--                <figure class="image is-256x256 float">-->
        <!--                  <img src="/kaczkoland.jpg">-->
        <!--                </figure>-->
        <!--              </center>-->
        <!--            </div>-->

        <!--          </div>-->
      </div>
    </div>
    <div class="hero-foot arrow" style="margin: 50px;">
      <div class="container has-text-centered clickable" onclick="document.getElementById('welcome').scrollIntoView({behavior: 'smooth'})">
          <span class="icon arrow">
            <i class="fas fa-chevron-down" style="font-size: 2em; color: black"></i>
          </span>
      </div>
    </div>
    <b-modal v-model="isActive" :width="640" scroll="keep">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <h1 class="title">Dołącz na serwer</h1>
            <h2 class="subtitle is-5">1. Uruchom Minecrafta, serwer działa na wersji 1.20.4.</h2>
            <h2 class="subtitle is-5">2. Kliknij przycisk Multiplayer (w polskiej wersji gry Tryb wieloosobowy)</h2>
            <img src="/images/t1.png">
            <h2 class="subtitle is-5">3. Kliknij przycisk Add server (w polskiej wersji gry Dodaj serwer)</h2>
            <img src="/images/t2.png">
            <h2 class="subtitle is-5">3. Jako Adres serwera wpisz <span style="font-weight: 300">Kaczkoland.pl</span>, jako nazwę serwera możesz wpisać <span style="font-weight: 300">Kaczkoland</span>. Później kliknij guzik Done (Gotowe)</h2>
            <img src="/images/t3.png">
            <h2 class="subtitle is-5">4. Znajdź serwer na liście serwerów i kliknij w niego 2 razy.</h2>
            <img src="/images/t4.png">
          </div>
        </div>
      </div>
    </b-modal>
  </section>
</template>

<script>
import Navbar from "@/layout/Navbar";

export default {
  name: "IndexHero",
  components: {
    Navbar
  },
  data() {
    return {
      isActive: false
    }
  }
}
</script>

<style scoped>
.hero.section {
  padding: 0;
}

.hero h1 {
  color: white
}

.hero h2 {
  color: white
}

.hero span {
  color: white
}

.header-image::before{
  content:"";
  background: url(/background.jpg);
  background-size:cover;
  position:absolute;
  top:0;
  right:0;
  bottom:0;
  left:0;
  opacity:.40
}
.clickable{
  cursor:pointer;
  outline:none
}

.arrow {
  color: black;
  animation: arrow 2s infinite;
}


@keyframes arrow {
  0% {transform: translateY(25%) }
  100% {transform: translateY(95%) }
}
</style>
