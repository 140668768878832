<template>
    <div class="card" style="background-color: #514f4f; border-radius: 64px">
      <div v-if="!notfound && !error">
        <figure class="image is-128x128 is-centered-image">
          <b-skeleton v-if="!player" :animated="true" width="128px" height="128px"></b-skeleton>
          <img v-else v-bind:src="'https://visage.surgeplay.com/head/512/' + uuid" alt="Image">
        </figure>
        <p class="title is-4 minecraft-font has-text-centered">
          <b-skeleton v-if="!player" :animated="true" height="25px" width="25%" position="is-centered"></b-skeleton>
          <b class="minecraft-font" v-if="player" style="display: flex; align-items: center; justify-content: center; gap: 10px">
            <span v-if="player.groups.length > 0" v-bind:style="{color: player.groups[0].group.prefix.color}">{{player.groups[0].group.prefix.name}}</span>
            <strong class="minecraft-font" style="color: white;">{{ $route.params.id }}</strong>
            <b-tooltip :label="(player && player.status.online ? 'Online - ' + player.status.server : 'Offline')" position="is-top">
              <span class="status-dot" :style="{'background-color': (player.status.online ? 'green' : 'red')}"></span>
            </b-tooltip>
          </b>
        </p>
        <div style="padding-left: 10px; padding-right: 10px;">
          <div class="columns is-multiline has-text-centered" style="color: white">
            <div class="column is-one-third" style="font-size: 1.2rem; padding: 5px 5px;">
              <span style="font-weight: 500;">Data rejestracji</span>
              <br>
              <b-skeleton v-if="!player" :animated="true" height="25px"></b-skeleton>
              <span v-if="player" style="font-weight: 300;">{{ dateToString(new Date(player.auth.regdate)) }}</span>
            </div>
            <div class="column is-one-third" style="font-size: 1.2rem; padding: 5px 5px;">
              <span style="font-weight: 500;">Data ostatniego logowania</span>
              <br>
              <b-skeleton v-if="!player" :animated="true" height="25px"></b-skeleton>
              <span v-if="player" style="font-weight: 300;">{{ dateToString(new Date(player.auth.lastLogin)) }}</span>
            </div>
            <div class="column is-one-third" style="font-size: 1.2rem; padding: 5px 5px;">
              <span style="font-weight: 500;">Typ konta (na serwerze)</span>
              <br>
              <b-skeleton v-if="!player" :animated="true" height="25px"></b-skeleton>
              <span v-if="player" style="font-weight: 300;">{{ player.auth.premium ? "Premium" : "Pirackie (hasło)" }}</span>
            </div>
<!--              <div class="column is-half" style="font-size: 1.2rem; padding: 5px 5px;"><span style="font-weight: 500;">Poziom</span><br><span style="font-weight: 300;">420</span></div>-->
<!--            <div class="column is-half" style="font-size: 1.2rem; padding: 5px 5px;"><span style="font-weight: 500;">Czas online</span><br><span style="font-weight: 300;">[WKRÓTCE]</span></div>-->
<!--            <div class="column is-half" style="font-size: 1.2rem; padding: 5px 5px;"><span style="font-weight: 500;">Konto Discord</span><br><span style="font-weight: 300;">[WKRÓTCE]</span></div>-->
          </div>
        </div>
        <br>
        <h1 class="title is-4 has-text-centered" style="font-weight: 600; margin-bottom: 2px;">Survival</h1>
        <br>
        <div style="padding-left: 10px; padding-right: 10px;">
          <div class="columns is-multiline has-text-centered" style="color: white">
<!--            <div class="column is-3" style="font-size: 1.2rem; padding: 2px 2px;"><span style="font-weight: 500;">Ilość zabitych graczy</span><br><span style="font-weight: 300;">{{ player.stats.survival.player_kills }}</span></div>-->
            <div class="column is-3" style="font-size: 1.2rem; padding: 2px 2px;">
              <span style="font-weight: 500;">Ilość zabitych graczy</span>
              <br>
              <b-skeleton v-if="!player" :animated="true" height="25px"></b-skeleton>
              <span v-else style="font-weight: 300;">{{ player.stats.survival.killedPlayers }}</span>
            </div>

            <div class="column is-3" style="font-size: 1.2rem; padding: 2px 2px;">
              <span style="font-weight: 500;">Ilość śmierci</span>
              <br>
              <b-skeleton v-if="!player" :animated="true" height="25px"></b-skeleton>
              <span v-else style="font-weight: 300;">{{ player.stats.survival.allDeaths }}</span>
            </div>

            <div class="column is-3" style="font-size: 1.2rem; padding: 2px 2px;">
              <span style="font-weight: 500;">Postawione bloki</span>
              <br>
              <b-skeleton v-if="!player" :animated="true" height="25px"></b-skeleton>
              <span v-else style="font-weight: 300;">{{ player.stats.survival.placedBlocks }}</span>
            </div>

            <div class="column is-3" style="font-size: 1.2rem; padding: 2px 2px;">
              <span style="font-weight: 500;">Zniszczone bloki</span>
              <br>
              <b-skeleton v-if="!player" :animated="true" height="25px"></b-skeleton>
              <span v-else style="font-weight: 300;">{{ player.stats.survival.minedBlocks }}</span>
            </div>

            <div class="column is-3" style="font-size: 1.2rem; padding: 2px 2px;">
              <span style="font-weight: 500;">Zrobione rzeczy</span>
              <br>
              <b-skeleton v-if="!player" :animated="true" height="25px"></b-skeleton>
              <span v-else style="font-weight: 300;">{{ player.stats.survival.craftedItems }}</span>
            </div>

            <div class="column is-3" style="font-size: 1.2rem; padding: 2px 2px;">
              <span style="font-weight: 500;">Zabite moby</span>
              <br>
              <b-skeleton v-if="!player" :animated="true" height="25px"></b-skeleton>
              <span v-if="player" style="font-weight: 300;">{{ player.stats.survival.killedMobs }}</span>
            </div>

            <div class="column is-3" style="font-size: 1.2rem; padding: 5px 5px;">
              <span style="font-weight: 500;">Czas online</span>
              <br>
              <b-skeleton v-if="!player" :animated="true" height="25px"></b-skeleton>
              <span v-if="player" style="font-weight: 300;">{{ readableTime(player.stats.survival.playedTime)}}</span>
            </div>

            <div class="column is-3" style="font-size: 1.2rem; padding: 5px 5px;">
              <span style="font-weight: 500;">Waluta serwerowa</span>
              <br>
              <b-skeleton v-if="!player" :animated="true" height="25px"></b-skeleton>
              <span v-if="player" style="font-weight: 300;">{{ player.stats.survival.money }}$</span>
            </div>

<!--            <div class="column is-3" style="font-size: 1.2rem; padding: 2px 2px;"><span style="font-weight: 500;">Wykopane diamenty</span><br><span style="font-weight: 300;">{{ player.stats.survival.minedDiamonds }}</span></div>-->
<!--            <div class="column is-3" style="font-size: 1.2rem; padding: 2px 2px;"><span style="font-weight: 500;">Przebyte bloki</span><br><span style="font-weight: 300;">{{ getDistance(player.stats.survival.walkedDistance) }}km</span></div>-->
          </div>
          <div class="columns">
            <div class="column is-half">
              <h2 class="title is-4" style="margin-bottom: 5px;">Ostatnie 5 zabójstw graczy</h2>
              <h3 class="subtitle is-5" v-if="player && player.stats.survival.killed.length === 0" style="margin-top: 5px;">Brak</h3>

              <template v-if="player">
                <PlayerHead v-for="p in player.stats.survival.killed" :key="p" :date="p.date" :name="p.killedName" :rank="p.rank" position="left"></PlayerHead>
              </template>

              <template v-else>
                <PlayerHeadSkeleton></PlayerHeadSkeleton>
              </template>
            </div>
            <div class="column is-half">
              <h1 class="title is-4" style="margin-bottom: 5px;">Ostatnie 5 śmierci przez graczy</h1>
              <h3 class="subtitle is-5" v-if="player && player.stats.survival.killers.length === 0" style="margin-top: 5px;">Brak</h3>

              <template v-if="player">
                <PlayerHead v-for="p in player.stats.survival.killers" :key="p" :date="p.date" :name="p.killerName" :rank="p.rank" position="right"></PlayerHead>
              </template>

              <template v-else>
                <PlayerHeadSkeleton></PlayerHeadSkeleton>
              </template>
            </div>
          </div>
        </div>
        <br>
<!--        <p class="subtitle is-6" style="margin-bottom: 0">Statystyki nie są odświeżane na żywo i mogą nie być w 100% poprawne!</p>-->
        <p class="subtitle" style="font-size: 1rem;">API do statystyk: <a href="https://api-docs.kaczkoland.pl" target="_blank" style="color: blue">kliknij tutaj</a></p>
      </div>
      <template v-if="notfound">
        <br><br>
        <span class="icon" style="color: red; font-size: 3em;">
          <i class="fas fa-times-circle"></i>
        </span>
        <br>
        <br>
        <h1 class="title">Nie znaleziono gracza w bazie!</h1>
      </template>
      <div v-if="error">
        <br><br>
        <span class="icon" style="color: red; font-size: 3em;">
          <i class="fas fa-times-circle"></i>
        </span>
        <br><br>
        <h1 class="title is-4">Wystąpił błąd. Sprawdź <a href="https://status.kaczkoland.pl" target="_blank" style="color: blue">status API</a></h1>
      </div>
      <br>
    </div>

</template>

<script>
import axios from 'axios';
import statsUtils from "@/statsUtils";
import {getPlayer} from "@/api";
import PlayerHead from "@/components/PlayerHead";
import PlayerHeadSkeleton from "@/components/PlayerHeadSkeleton";

export default {
  name: "UserCard",
  components: {PlayerHead, PlayerHeadSkeleton},
  data: function () {
    return {
      player: undefined,
      notfound: false,
      error: false,
      uuid: "",
      banned: false,
      ban_time: null,
      online: "",
      survivalStatistics: {

      }
    }
  }, async created() {
    const playerData = await getPlayer(this.$route.params.id);

    if(!playerData.success) this.error = true;

    if(playerData.auth === undefined) {
      this.notfound = true;
      return;
    }

    if(playerData.success) this.player = playerData;

    axios.get("https://api.minetools.eu/uuid/" + this.$route.params.id).then(result => {
      console.log(result.data)
      if(result.data.status === "ERR") {
        this.uuid = "X-Steve";
      } else {
        this.uuid = result.data.id;
      }
    });
  }, methods: {
    dateToString(date) {
      return this.twoNumbersAlways(date.getDate()) + "." + this.twoNumbersAlways(date.getMonth()+1) + "." + date.getFullYear() + " " + this.twoNumbersAlways(date.getHours()) + ":" + this.twoNumbersAlways(date.getMinutes()) + ":" + this.twoNumbersAlways(date.getSeconds());
    }, twoNumbersAlways(d) {
      if(d < 10) return "0" + d;
      return d;
    },
    readableTime: statsUtils.readableTime,
    getDistance: statsUtils.getDistance
  }
}
</script>

<style scoped>
.player-card {
  padding: 5px 5px;
  background-color: #4a4a4a;
}
.hero {
  background-color: #3b3939;
}
.is-vcentered {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.is-256x256 {
  height: 256px;
  width: 256px;
}

@keyframes float {
  0% {
    /* box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6); */
    transform: translatey(20px);
  }
  50% {
    /* box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2); */
    transform: translatey(-20px);
  }
  100% {
    /* box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6); */
    transform: translatey(20px);
  }
}

.float {
  transform: translatey(0px);
  animation: float 6s ease-in-out infinite;
}

html {
  box-sizing: border-box;
}
*,*::before,*::after {
  box-sizing: inherit;
}
body {
  margin: 0;
}

.underline {
  /*text-decoration: none;*/
  /*border-bottom: 1px solid #639fff;*/
  /*text-decoration-color: #639fff;*/
  font-weight: bold;
}

.arrow {
  animation: arrow 2s infinite;
  color: white;
}


@keyframes arrow {
  0% {transform: translateY(25%) }
  100% {transform: translateY(95%) }
}

.status-dot {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: inline-block;
}
</style>
