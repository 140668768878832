<template>
  <section class="section" style="background-color: #3b3939;">
    <div class="container has-text-centered">
      <h1 class="title">Regulamin Kaczkolandu</h1>
      <a href="/regulamin-kaczkolandu.pdf">Pobierz regulamin w PDFie</a>
    </div>
    <div class="container is-medium">
      <object data="/regulamin-kaczkolandu.pdf" type="application/pdf" width="100%" height="500px">
        <p>Unable to display PDF file. <a href="/regulamin-kaczkolandu.pdf">Download</a> instead.</p>
      </object>
    </div>
<!--    <div class="container is-medium">-->
<!--      <h1 class="title is-4 has-text-centered">Postanowienia ogolne</h1>-->
<!--      <h1 class="subtitle is-6">-->
<!--        <ol>-->
<!--          <li>Użytkownik korzystając z usług Kaczkolandu jest zobowiązany do przeczytania regulaminu.</li>-->
<!--          <li>Serwer Minecraft Kaczkoland.pl nie jest powiązany w żaden sposób z firmą Mojang AB.</li>-->
<!--          <li>Administracja zastrzega sobie prawo do zmiany regulaminu (usuwanie, zmiana oraz dodawanie treści), bez poinformowania o tym użytkownika.</li>-->
<!--          <li>Administracja ma prawo przerwać w każdym momencie edycję/sezon lub zakończyć działanie serwera. Wszystkie budowle, przedmioty, wirtualne pieniądze, rangi (rangi podczas zakończenia serwera) itd. mogą wtedy zostać utracone.</li>-->
<!--          <li>Za nieprzestrzeganie regulaminu gracz może zostać ukarany.</li>-->
<!--          <li>Administracja może karać za rzeczy nie podane w regulaminie, jeśli uzna to za niestosowne.</li>-->
<!--          <li>Użytkownik korzystający z usług jest zobowiązany do:</li>-->
<!--          <ol type="a">-->
<!--            <li>podawania prawdziwych informacji,</li>-->
<!--            <li>współpracy z administracją usług.</li>-->
<!--          </ol>-->
<!--        </ol>-->
<!--      </h1>-->
<!--      <h1 class="title is-4 has-text-centered">Regulamin serwera Minecraft</h1>-->
<!--      <h1 class="subtitle is-6">-->
<!--        <ol>-->
<!--          <li>Ogólne</li>-->
<!--          <ol type="a">-->
<!--            <li>W przypadku złamania regulaminu możesz zostać ukarany ostrzeżeniem, wyciszeniem, jailem (więzieniem), wyrzuceniem lub/oraz zablokowaniem dostępu do serwera, czasowo lub na stałe.</li>-->
<!--            <li>Statystyki gracza, ranga, data ostatniego logowania, rejestracji, liczba wirtualnej waluty oraz inne są wyświetlane na stronie internetowej serwera oraz mogą być również wyświetlane w innych miejscach poprzez użycie naszego API.</li>-->
<!--            <li>Zbanowani, wyciszeni, wyrzuceni oraz ostrzeżeni gracze mogą być wyświetlani na stronie internetowej oraz w innych miejscach.</li>-->
<!--            <li>Zabronione jest wykorzystywanie błędów/bugów/glitchów serwera oraz gry. Jeśli gracz znajdzie błąd i go zgłosi (przy tym nie będzie go używać), ominie karę.</li>-->
<!--             <ol type="A">-->
<!--              <li>bugowanie się perłami również podchodzi pod podpunkt powyżej</li>-->
<!--            </ol>-->
<!--            <li>Zabronione jest używaneie multikont oraz omijanie kary używając ich (innego/innych kont, na którym gracz nie ma kary).</li>-->
<!--            <li>Zabronione jest reklamowanie stron, innych serwerów Minecraft, serwerów Discord, kanałów Youtube itd. w jakikolwiek sposób.</li>-->
<!--            <ol type="A">-->
<!--              <li>Za reklamowanie uznane jest też pytanie się kogoś, czy chcę dołączyć na dany serwer Discord, Minecraft, zasubskrybować kanał youtube itd.</li>-->
<!--            </ol>-->
<!--            <li>Zabronione jest używanie modyfikacji oraz macro (auto-clickerów, program lub mod, dzięki któremu komputer klika automatycznie zamiast gracz klika na myszce). Wyjątkiem modyfikacji są minimapa oraz optifine.</li>-->
<!--            <li>Zabronione jest używanie xraya (czyli używanie przerobionego klienta gry, resource packa lub w innej formie możliwości widzenia przez ściany określone bloki).</li>-->
<!--            <li>Zabronione jest prowokowanie, zmuszanie oraz namawianie graczy do łamania regulaminu.</li>-->
<!--            <li>Zabronione jest tworzenie oraz używanie afczarek (konstrukcja, dzięki której serwer Cię nie wyrzuca po określonym czasie).</li>-->
<!--            <li>Zabronione jest teleportowanie graczy do pomieszczeń, z których gracz nie może wyjść.</li>-->
<!--            <li>Zabronione jest "trapowanie", czyli robienie pułapek na graczy (np. systemy redstone-owe).</li>-->
<!--            <li>Zabronione jest utrudnianie rozgrywki innym graczom, np. oszukując, okłamując, ciągle zabijając.</li>-->
<!--            <li>Zabronione jest używanie działek, aby griefować działki innych graczy:</li>-->
<!--            <ol type="A">-->
<!--              <li>stawianie działki obok innego gracza i stawianie losowych bloków na niej.</li>-->
<!--            </ol>-->
<!--            <li>Zabronione jest prezentowanie treści rasistowskich, nazistowskich, wulgarnych, pornograficznych w jakiejkolwiek postaci (m.in. na czacie, jako budowla, jako nick oraz inne).</li>-->
<!--            <li>Zabronione jest podawanie danych graczy (chodzi o personalne dane typu imię, nazwisko, adres itd.) w jakikolwiek sposób.</li>-->
<!--            <li>Zabronione jest obrażanie innych graczy oraz osób w jakikolwiek sposób.</li>-->
<!--            <li>Dozwolone jest używanie fullbrighta.</li>-->
<!--          </ol>-->
<!--          <li>Czat</li>-->
<!--          <ol type="a">-->
<!--            <li>Zabronione jest spamowanie, floodowanie, używania mowy nienawiści, pisanie dużymi literami itd.</li>-->
<!--            <li>Zabronione jest proszenie o rangi, itemy, wirtualne pieniądze itd.</li>-->
<!--            <li>Zabronione jest używanie brzydkich słów (wulgaryzmów) oraz omijanie cenzury.</li>-->
<!--          </ol>-->
<!--          <li>Świat</li>-->
<!--          <ol type="a">-->
<!--            <li>Zabronione jest griefowanie mapy. Griefowanie to:</li>-->
<!--            <ol type="A">-->
<!--              <li>celowe niszczenie okolic działek, spawnu (przypadkowe stawianie bloków nie jest zabronione),</li>-->
<!--              <li>robienie lava-castów (wylewanie lawy, a nad nią wodę aby stworzyć dużo bruku/cobblestone),</li>-->
<!--              <li>budowanie nieprzyzwoitych budowli.</li>-->
<!--            </ol>-->
<!--            <li>Administrator może naprawić teren, jesli jest on bardzo zgriefowany.</li>-->
<!--            <li>Zabronione jest posiadanie mechanizmów zakłócających pracę serwera.</li>-->
<!--            <li>Zabronione jest przepychanie graczy za teren/region w celu zabicia ich.</li>-->
<!--          </ol>-->
<!--          <li>Konto</li>-->
<!--          <ol type="a">-->
<!--            <li>Zabronione jest podszywanie się pod administrację oraz inne osoby.</li>-->
<!--            <li>Twój nick nie może zawierać treści wulgarnych, obraźliwych itp.</li>-->
<!--            <li>Monet serwerowych ($) nie da się wypłacić na realną walutę. Jeśli jest gdzieś mówione o dolarach np. 400$ za coś, chodzi o walutę serwerową.</li>-->
<!--            <li>Sprzedawanie konta, przedmiotów, wirtualnych pieniędzy oraz innych rzeczy z serwera za prawdziwą walutę jest zabronione (chodzi o waluty np. EURO, PLN, USD oraz kryptowaluty np. BITCOIN, DOGECOIN, ETHEREUM).</li>-->
<!--          </ol>-->
<!--          <li>Administracja ma prawo do sprawdzenia gracza, czy nie używa on niedozwolonych modyfikacji klienta.</li>-->
<!--          <ol type="a">-->
<!--            <li>W tym celu możesz zostać poproszony o ściągnięcie programów, wymaganych do zweryfikowania twojego klienta gry.</li>-->
<!--            <li>W przypadku braku możliwości zweryfikowania twojego klienta gry, zostaniesz ukarany.</li>-->
<!--            <li>Jeśli nie będziesz wykonywać poleceń administracji, możesz zostać ukarany.</li>-->
<!--            <li>Masz prawo do przyznania się używania niedozwolonych modyfikacji klienta gry.</li>-->
<!--          </ol>-->
<!--          <li>Za nieprzestrzeganie regulaminu gracz może zostać ukarany ostrzeżeniem, wyciszeniem, wyrzuceniem lub zablokowaniem dostępu do serwera, tymczasowo lub na stałe.</li>-->
<!--          <li>Administracja nie odpowiada za utracone przedmioty, wirtualne pieniądze itd. Administracja nie ma obowiązku zwrotu tych rzeczy.</li>-->
<!--          <li>Administracja może karać za rzeczy nie podane w regulaminie, jeśli uzna je za niestosowne.</li>-->
<!--          <li>Działki użytkownika mogą zostać usuniętę po 30 dniach nieobecności.</li>-->
<!--        </ol>-->
<!--      </h1>-->
<!--      <h1 class="title is-4 has-text-centered">Regulamin serwera Discord</h1>-->
<!--      <h1 class="subtitle is-6">-->
<!--        <ol>-->
<!--          <li>Dołączając na serwer Discord Kaczkolandu akceptujesz TOS (Terms of Service) Discorda <a href="https://discord.com/tos" target="_blank">discord.com/tos</a> oraz ten regulamin.</li>-->
<!--          <li>Administracja może karać za rzeczy nie podane w regulaminie, jeśli uzna to za niestosowne.</li>-->
<!--          <li>W przypadku złamania regulaminu możesz zostać ukarany ostrzeżeniem, wyciszeniem, wyrzuceniem lub/oraz zablokowaniem dostępu do serwera, czasowo lub na stałe.</li>-->
<!--          <li>Zabrania się:</li>-->
<!--          <ol type="a">-->
<!--            <li>spamowania, floodowania na czacie,</li>-->
<!--            <li>spamowania emotkami,</li>-->
<!--            <li>obrażania innych użytkowników,</li>-->
<!--            <li>podszywania się pod inne osoby,</li>-->
<!--            <li>reklamowania się w prywatnej wiadomości,</li>-->
<!--            <li>krzyczenia na kanałach głosowych.</li>-->
<!--          </ol>-->
<!--          <li>Komendy botów można używać jedynie na kanałach do tego przeznaczonych.</li>-->
<!--          <li>Użytkownik, zanim zacznie używać jakiś kanał na Discordzie powinien sprawdzić przypięte wiadomości oraz opis kanału. Za nieprzestrzeganie zasad lub informacji tam napisanej użytkownik może zostać ukarany.</li>-->
<!--          <li>Obowiązują również zasady z Regulamin serwera Minecraft, sekcja Czat.</li>-->
<!--          <li>Zabronione jest reklamowanie się w wiadomości prywatnej. Pod ten podpunkt podchodzi również:</li>-->
<!--          <ol type="a">-->
<!--            <li>pytanie się, czy możesz wysłać zaproszenie.</li>-->
<!--          </ol>-->
<!--        </ol>-->
<!--      </h1>-->
<!--      <h1 class="title is-4 has-text-centered">Regulamin sklepu serwera</h1>-->
<!--      <h1 class="subtitle is-6">-->
<!--        <ol>-->
<!--          <li>Koszt usługi jest jednorazowy.</li>-->
<!--          <li>Za zakup usługi otrzymujesz korzyści podane na stronie sklepu.</li>-->
<!--          <li>W przypadku otrzymania blokady na konto, nie przysługuje Ci możliwość zwrotu, przedłużenia rangi o czas blokady na konto ani przeniesienia rangi na inne konto.</li>-->
<!--          <li>Nie ponosimy odpowiedzialności za błędnie podane dane.</li>-->
<!--          <li>Problemy z usługami, prośbe o zwrot oraz reklamacje prosimy zgłaszać na adres email <a href="mailto:kaczkoland@rkuba.pl">kaczkoland@rkuba.pl</a> lub na Discordzie w prywatnej wiadomości: rkubapl#0556 podając przy tym numer zamówienia, kwotę, datę i czas transakcji oraz kanał płatności.</li>-->
<!--          <li>Płatności są obsługiwane przez firmę LVL UP z siedzibą przy ul. Cyfrowa 6 71-441 Szczecin, NIP: 7412110339, REGON: 321345466 za pośrednictwem zewnętrznych firm. Regulamin znajduje się na ich stronie płatności.</li>-->
<!--          <li>Cena podana na stronie jest podana w polskich złotych (PLN), może być wymagane dopłacenie prowizji.</li>-->
<!--          <li>Zakupione usługi mogą zostać utracone podczas zakończenia projektu.</li>-->
<!--          <li>Administracja zastrzega sobie prawo do zmiany cen oraz korzyści z usług.</li>-->
<!--          <li>Usługa może zostać wycofana ze sklepu, użytkownik wtedy nie traci korzyści, ale nie posiada możliwości przedłużenia rangi.</li>-->
<!--          <li>Podczas zakupu usługi lub używania vouchera należy być online na serwerze.</li>-->
<!--          <li>Korzyści z zakupu usługi są aktywne przez czas trwania usługi.</li>-->
<!--          <li>Jeżeli serwer jest offline przez minimum 24h, możesz otrzymać przedłużenie rangi.</li>-->
<!--          <ol type="a">-->
<!--            <li>Możliwość przedłużenia rangi rozpoczyna się od 24h serwera offline.</li>-->
<!--            <li>Każde kolejne 24h można odzyskać.</li>-->
<!--            <li>Należy napisać na adres e-mail <a href="mailto:kaczkoland@rkuba.pl">kaczkoland@rkuba.pl</a> lub skontaktować się przez Discorda: rkubapl#0556.</li>-->
<!--          </ol>-->
<!--        </ol>-->
<!--      </h1>-->
<!--      <h1 class="title is-4 has-text-centered">Polityka prywatności</h1>-->
<!--      <h1 class="subtitle is-6">-->
<!--        <ol>-->
<!--          <li>Miejsca logowania i wprowadzania danych osobowych są chronione w warstwie transmisji (certyfikat SSL). Dzięki temu dane osobowe i dane logowania, wprowadzone na stronach, zostają zaszyfrowane w komputerze użytkownika i mogą być odczytane jedynie na docelowym serwerze.</li>-->
<!--          <li>Hasła użytkowników są przechowywane w postaci hashowanej. Funkcja hashująca działa jednokierunkowo - nie jest możliwe odwrócenie jej działania, co stanowi obecnie współczesny standard w zakresie przechowywania haseł użytkowników.</li>-->
<!--          <li>Masz prawo do usunięcia, ograniczenia przetwarzania, sprostowania oraz do wglądu twoich danych. W takim wypadku należy skontaktować się z administracją serwera poprzez adres e-mail <a href="mailto:kaczkoland@rkuba.pl">kaczkoland@rkuba.pl</a>.</li>-->
<!--          <li>Jeżeli użytkownik posiada zablokowane konto na IP, możemy odmówić usunięcia twoich danych w celu walki z nieuczciwymi graczami.</li>-->
<!--          <li>Dane osobowe pozostawione w usługach nie zostaną sprzedane ani udostępnione osobom trzecim, zgodnie z przepisami Ustawy o ochronie danych osobowych.</li>-->
<!--          <li>Następujące rodzaje danych osobowych mogą być gromadzone, przechowywane i wykorzystywane:</li>-->
<!--          <ol type="a">-->
<!--            <li>informacje o urządzeniu, w tym adres IP, lokalizacja geograficzna, typ i wersja przeglądarki oraz system operacyjny,</li>-->
<!--            <li>informacje o Twoich wizytach i korzystaniu z tej witryny, w tym źródło odesłań, długość wizyty, wyświetlenia stron i ścieżki nawigacji w witrynie,</li>-->
<!--            <li>informacje, takie jak adres e-mail, hasło, które podajesz podczas rejestracji lub z własnej woli,</li>-->
<!--            <li>informacje wprowadzane podczas korzystania z usług na naszej stronie internetowej lub w sklepie,</li>-->
<!--            <li>wszelkie inne dane osobowe, które nam przesyłasz.</li>-->
<!--          </ol>-->
<!--          <li>W niektórych sytuacjach Administrator ma prawo przekazywać Twoje dane osobowe innym odbiorcom, jeśli będzie to niezbędne do wykonania zawartej z Tobą umowy lub do zrealizowania obowiązków ciążących na Administratorze. Dotyczy to takich grup odbiorców:</li>-->
<!--          <ol type="a">-->
<!--            <li>osoby upoważnione przez nas, pracownicy i współprcownicy, którzy muszą mieć dostęp do danych osobowych w celu wykonywania swoich obowiązków,</li>-->
<!--            <li>firma hostingowa,</li>-->
<!--            <li>firmy obsługująca mailingi,</li>-->
<!--            <li>firmy, z którymi Administrator współpracuje w zakresie marketingu własnego,</li>-->
<!--            <li>kancelarie prawne i windykatorzy,</li>-->
<!--            <li>organy publiczne.</li>-->
<!--          </ol>-->
<!--          <li>Informacje zachowaniu użytkowników w serwisie mogą podlegać logowaniu. Dane te są wykorzystywane w celu administrowania serwisem.</li>-->
<!--          <li>Zewnętrzne firmy, z których korzystamy, mogą mieć wgląd do twoich danych, mogą je przetwarzać oraz zapisywać:</li>-->
<!--          <ol type="a">-->
<!--            <li><a href="https://contabo.com" target="_blank">contabo.com</a></li>-->
<!--            <li><a href="https://funkemunky.cc/vpn/" target="_blank">funkemunky.cc</a></li>-->
<!--            <li><a href="https://cloudflare.com" target="_blank">cloudflare.com</a></li>-->
<!--            <li><a href="https://tcpshield.com" target="_blank">tcpshield.com</a></li>-->
<!--            <li><a href="https://mclist.pl" target="_blank">mclist.pl</a>**</li>-->
<!--            <li><a href="https://poszukaj.se" target="_blank">poszukaj.se</a>**</li>-->
<!--            <li><a href="https://najserwery.pl" target="_blank">najserwery.pl</a>**</li>-->
<!--            <li><a href="https://serwery-minecraft.pl" target="_blank">serwery-minecraft.pl</a>**</li>-->
<!--            <ol type="A">-->
<!--              <li>** Twoje IP lub nazwa użytkownika jest wysyłana do tych usług w celu zweryfikowania Twojego głosu na nasz serwer.</li>-->
<!--            </ol>-->
<!--          </ol>-->
<!--          <li>Używamy <a href="https://plausible.io/" target="_blank">Plausible.io</a> (<a href="https://plausible.io/data-policy" target="_blank">Polityka Prywatności</a>) do statystyk ze stron Kaczkolandu.</li>-->
<!--        </ol>-->
<!--      </h1>-->
<!--      <p style="color: white">Ostatnia modyfikacja: 6 marca 2022 r.</p>-->
<!--    </div>-->
  </section>
</template>

<script>
export default {
  name: "Rules"
}
</script>

<style scoped>
li{
  margin: 10px 0;
}

ol ol {
  margin-left: 2em;
}

</style>
