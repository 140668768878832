<template>
<div class="hero is-info">
    <div class="hero-head">
      <Navbar></Navbar>
    </div>
    <div class="hero-body">
      <div class="container has-text-centered">
        <h1 class="title">Kaczkoland.pl</h1>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/layout/Navbar";

export default {
  name: "SecondHero",
  components: {
    Navbar
  }
}
</script>

<style scoped>

</style>