<template>
  <section class="section">
    <div class="container has-text-centered">
      <h1 class="title">Witaj na Kaczkolandzie!</h1>
      <h2 class="subtitle">Kaczkoland to serwer survival + działki na wersję 1.16.4+.<br> Na tej stronie możesz dowiedzieć się podstawowe rzeczy, które możesz zrobić na serwerze.</h2>
    </div>
    <br>
    <div class="container">
      <div class="columns">
        <div class="column is-half is-vertical-center">
          <h1 class="title has-text-centered">Witaj na serwerze</h1>
          <h2 class="subtitle has-text-centered">Komendy, które można użyć na początek:<br><b>/pomoc</b> - więcej podstawowych komend<br><b>/czas</b>, <b>/pogoda</b> - głosowanie o zmiany czasu lub pogody<br><b>/premium</b> - uruchom logowanie premium <b>użyj tej komendy tylko, jeśli posiadasz Minecraft Premium!!!</b><br><b>/skin [nick/url]</b> - ustawia skin osoby premium lub z linku<br><b>/discord</b> - link do serwera Discord<br><b>/discord link</b> - połącz swoje konto Discord z Minecraft<br><b>/newbie disable</b> - wyłącza ochronę od PVP na start<br><b>/rtp</b> - teleportacja w losow miejsce na mapie</h2>
        </div>
        <div class="column is-half">
          <figure class="image is-256x256">
            <img src="/images/img3.png">
          </figure>
        </div>
      </div>
    </div>
    <br>
    <div class="columns">
      <div class="column is-half">
        <figure class="image is-256x256">
          <img src="/images/img.png">
        </figure>
      </div>
      <div class="column is-half is-vertical-center">
          <h1 class="title has-text-centered">Działki</h1>
          <h2 class="subtitle has-text-centered">Na serwerze możesz założyć działkę (region), na której inni gracze (oprócz Ciebie i dodanych osób) nie mogą niszczyć rzeczy!<br><b>/ps add [nick]</b> - dodaj gracza do działki (na której stoisz)<br><b>/ps remove [nick]</b> - usuwa gracza z działki (na której stoisz)<br><b>/ps help</b> - wyświetla wszystkie komendy dotyczące działek<br><b>/dzialka-crafting</b> - pokazuje, jak zrobić nową działkę</h2>
      </div>
    </div>
    <br>
    <div class="container">
      <div class="columns">
        <div class="column is-half is-vertical-center">
          <h1 class="title has-text-centered">Graj ze znajomymi</h1>
          <h2 class="subtitle has-text-centered">Na serwerze mozesz grać ze znajomymi.<br><b>/tpa [nick]</b> - wyślij proźbę o teleportację<br><b>/tpaccept lub /tpadeny</b> - akceptowanie prośby o teleportację lub odrzucanie</h2>
        </div>
        <div class="column is-half">
          <figure class="image is-256x256">
            <img src="/images/img2.png">
          </figure>
        </div>
      </div>
    </div>
    <br>
    <div class="container">
      <div class="columns">
        <div class="column is-half">
          <figure class="image is-256x256">
            <img src="/images/img4.png">
          </figure>
        </div>
        <div class="column is-half is-vertical-center">
          <h1 class="title has-text-centered">Sklep na spawnie</h1>
          <h2 class="subtitle has-text-centered">Na spawnie (<b>/spawn</b>) możesz znaleść sklep z rzeczami, które możesz kupować lub sprzedawać.<br><b>PPM (prawy przycisk myszy lub klawisz do stawiania)</b> - kupienie 1 sztuki rzeczy<br><b>LPM (lewy przecisk myszy lub klawisz do niszczenia)</b> - sprzedawanie 1 sztuki rzeczy z ekwipunku<br><b>/money</b> - sprwadź stan konta</h2>
        </div>
      </div>
    </div>
    <br>
    <div class="container">
      <div class="columns">
        <div class="column is-half is-vertical-center">
          <h1 class="title has-text-centered">Rangi na serwerze</h1>
          <h2 class="subtitle has-text-centered">Staramy się nie robić rang, które mają bardzo dużo przywilejów, które faworyzują ich w grze.<br><br><a target="_blank" href="https://sklep.kaczkoland.pl/rangi" rel="noopener" class="button is-danger">Zobacz przywileje rang</a></h2>
        </div>
        <div class="column is-half">
          <figure class="image is-256x256">
            <img src="/images/img5.png">
          </figure>
        </div>
      </div>
    </div>
    <br>
    <div class="container">
      <div class="columns">
        <div class="column is-half">
          <figure class="image is-256x256">
            <img src="/images/img6.png">
          </figure>
        </div>
        <div class="column is-half is-vertical-center">
          <h1 class="title has-text-centered">Chcesz dowiedzieć się więcej?</h1>
          <h2 class="subtitle has-text-centered">Zobacz naszą dokumentację, gdzie możesz dowiedzieć się więcej.<br><br><a href="https://docs.kaczkoland.pl" class="button is-info" target="_blank" rel="noopener">Przejdź do dokumentacji</a></h2>
        </div>
      </div>
    </div>
    <br>
    <div class="container has-text-centered">
      <h1 class="title">Obserwuj @kaczkoland na social mediach</h1>
      <h2 class="subtitle" style="margin-bottom: 15px">Na naszych social mediach, <a href="https://discord.kaczkoland.pl" target="_blank" rel="noopener">Discordzie</a> oraz <a href="https://blog.kaczkoland.pl" rel="noopener" target="_blank">blogu</a> wrzucamy informację o rekrutacjach, wydarzeniach (eventach) itd.</h2>
      <SocialMedia></SocialMedia>
    </div>
  </section>
</template>

<script>
import SocialMedia from "@/components/SocialMedia";
export default {
name: "Start",
  components: {SocialMedia}
}
</script>

<style scoped>
.is-vertical-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.image.is-256x256 {
  height: 256px;
  width: 256px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>