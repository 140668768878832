<template>
  <div id="welcome">
    <section class="section" style="background-color: rgb(38, 38, 38); margin-bottom: 0">
      <div class="container has-text-centered" style="margin-bottom: 20px;">
        <h1 class="title" style="color: white;">Witaj na Kaczkolandzie!</h1>
        <h1 class="subtitle has-text-weight-medium" style="color: white;">Kaczkoland.pl to mały serwer survival + działki, który działa od 2020 roku. </h1>
      </div>
      <div class="container has-text-centered">
        <div class="columns is-multiline is-centered">
          <div class="column is-3" style="padding-top: 24px;">
                            <span class="icon" style="font-size: 3.5em; color: white">
                                <i class="fas fa-server"></i>
                            </span>
            <h1 class="title is-4 has-text-centered" style="color: white;">Stabilna rozgrywka</h1>
            <h2 class="subtitle is-6" style="color: white">Staramy się, aby serwer działał jak <b>najdłużej</b> i <b>stablinie</b>!</h2>
          </div>
          <div class="column is-3" style="padding-top: 24px;">
                            <span class="icon" style="font-size: 3.5em; color: white">
                                <i class="fas fa-money-bill"></i>
                            </span>
            <h1 class="title is-4 has-text-centered" style="color: white;">Brak dużej przewagi rang</h1>
            <h2 class="subtitle is-6" style="color: white">Rangi <b>nie mają dużej przewagi</b> na serwerze, są one bardziej kosmetyczne.</h2>
          </div>
          <div class="column is-3" style="padding-top: 24px;">
                            <span class="icon" style="font-size: 3.5em; color: white">
                                <i class="fas fa-user-friends"></i>
                            </span>
            <h1 class="title is-4" style="color: white">Non-premium</h1>
            <h2 class="subtitle is-6" style="color: white">Nie musisz posiadać oryginalnego Minecrafta, aby grać u nas na serwerze!</h2>
          </div>
          <!--          <div class="column is-3" style="padding-top: 24px;">-->
          <!--                            <span class="icon" style="font-size: 3.5em; color: white">-->
          <!--                                <i class="fas fa-external-link-alt"></i>-->
          <!--                            </span>-->
          <!--            <h1 class="title is-4" style="color: white">Najnowsza wersja gry</h1>-->
          <!--            <h2 class="subtitle is-6" style="color: white">Nasz serwer działa na <span class="underline">najnowszej wersji</span> Minecraft! Dzięki temu będziesz aktualny z <span class="und-->
          <!--">najnowszymi aktualizacjami Minecrafta</span>!</h2>-->
          <!--          </div>-->
          <div class="column is-3" style="padding-top: 24px;">
                            <span class="icon" style="font-size: 3.5em; color: white">
                                <i class="fas fa-level-up-alt"></i>
                            </span>
            <h1 class="title is-4" style="margin-top: 20px; color: white">Śledź swoje statystyki</h1>
            <h2 class="subtitle is-6" style="color: white">Na naszej <span class="underline">stronie internetowej</span> możesz <b>wyświeltać</b> swoje lub innych graczy <b>statystyki</b>.</h2>
            <router-link class="button is-primary" rel="noopener" to="/statystyki" target="_blank">Zobacz statystyki</router-link>
            <!--            <a class="button is-danger" rel="noopener" href="/statystyki" target="_blank">Zobacz statystyki</a>-->
          </div>
          <div class="column is-3" style="padding-top: 24px;">
                            <span class="icon" style="font-size: 3.5em; color: white">
                                <i class="fab fa-discord"></i>
                            </span>
            <h1 class="title is-4" style="margin-top: 20px; color: white">Serwer Discord</h1>
            <h2 class="subtitle is-6" style="color: white">Posidamy serwer Discord, na którym możesz <span class="underline">porozmawiać</span> z innymi graczami lub <span class="underline">rozwiązać jakiś problem</span>.</h2>
            <a class="button is-primary" rel="noopener" href="https://discord.kaczkoland.pl" target="_blank">Dołącz już teraz</a>
          </div>
          <div class="column is-3" style="padding-top: 24px;">
                            <span class="icon" style="font-size: 3.5em; color: white">
                                <i class="fas fa-calendar-week"></i>
                            </span>
            <h1 class="title is-4" style="margin-top: 10px; color: white;">Różne konkursy</h1>
            <h2 class="subtitle is-6" style="color: white">Organizujemy <b>różne konkursy</b>. Jeśli nie jesteś dobry w jednej konkurencji możesz wziąść udział w innej!</h2>
            <a class="button is-primary" rel="noopener" href="https://blog.kaczkoland.pl/tag/konkursy/" target="_blank">Zobacz konkursy</a>
          </div>
        </div>
      </div>
    </section>
    <section class="section" style="background-color: rgb(44, 44, 44); margin-bottom: 0">
      <div class="container has-text-centered" style="margin-bottom: 20px;">
        <h1 class="title" style="color: white; margin-bottom: 30px">Tryby na Kaczkolandzie</h1>
      </div>
      <div class="container">
        <div class="columns is-vcentered">
          <div class="column is-half">
            <img src="/images/survival.png" style="border-radius: 16px;">
          </div>
          <div class="column is-half">
            <h1 class="title has-text-centered" style="color: white">Survival + działki (1.20.4+)</h1>
            <h2 class="subtitle has-text-centered" style="color: white">Walcz o przetrwanie, załóż działkę, zarabiaj wirtualne monety. </h2>
          </div>
        </div>
<!--        <div class="columns is-vcentered reverse-columns">-->
<!--          <div class="column is-half">-->
<!--            <h1 class="title has-text-centered" style="color: white">Berek</h1>-->
<!--            <h2 class="subtitle has-text-centered" style="color: white">Minigra, w której udział bierze od 2 do 6 graczy. Jedna osoba staje się osobą goniącą i jej celem jest przekazanie swojej roli goniącego innemu graczowi poprzez uderzenie lub strzał łuku. Po 60 sekundach osoba goniąca odpada i rozpoczyna się kolejna runda. Wygrywa osoba, która w ostatniej rundzie (gdy zostanie 2 graczy) na końcu czasu nie będzie osobą goniącą.</h2>-->
<!--          </div>-->
<!--          <div class="column is-half">-->
<!--            <img src="/images/berek.jpg" style="border-radius: 16px;">-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </section>
<!--    <div style="background-color: #3b3939;  margin-bottom: 0;">-->
<!--      <svg style="display: block;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#f3f4f5" fill-opacity="1" d="M0,224L48,229.3C96,235,192,245,288,245.3C384,245,480,235,576,213.3C672,192,768,160,864,176C960,192,1056,256,1152,266.7C1248,277,1344,235,1392,213.3L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>-->
<!--    </div>-->
<!--    <section class="section" style="background-color: #f3f4f5;">-->
<!--      <div class="container has-text-centered" style="margin-bottom: 20px;">-->
<!--        <h1 class="title is-black-color">Statystyki</h1>-->
<!--      </div>-->
<!--      <div class="container">-->
<!--        <div class="columns is-centered">-->
<!--          <Stat type="zabitych graczy (Survival)" v-bind:data="stats.kills" name="player_kills" v-bind:ranks="ranks" color="black"></Stat>-->
<!--        </div>-->
<!--        <div class="buttons is-centered">-->
<!--          <router-link to="/statystyki" class="button is-primary is-medium">Zobacz więcej</router-link>-->
<!--        </div>-->
<!--      </div>-->
<!--    </section>-->
<!--    <div style="background-color: #3b3939;">-->
<!--      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#f3f4f5" fill-opacity="1" d="M0,224L60,234.7C120,245,240,267,360,261.3C480,256,600,224,720,202.7C840,181,960,171,1080,170.7C1200,171,1320,181,1380,186.7L1440,192L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"></path></svg>-->
<!--    </div>-->
    <section class="section" style="background-color: rgb(44, 44, 44);">
      <div class="container has-text-centered" style="margin-bottom: 25px;">
        <h1 class="title" style="color: white">Ostatnie posty na blogu</h1>
        <h1 class="subtitle" style="color: white" v-if="posts.length === 0">Nie znaleziono żadnego posta!</h1>
      </div>
      <div class="container">
        <div class="columns">
          <div class="column is-4" v-for="post in posts.slice(0,3)" :key="post">
            <div class="card">
              <div class="card-image">
                <figure class="image is-4by3" v-if="post.feature_image">
                  <img v-bind:src="post.feature_image" alt="Placeholder image">
                </figure>
              </div>
              <div class="card-content">
                <div class="media">
                  <div class="media-content">
                    <p class="title is-4">{{ post.title }}</p>
                    <p class="subtitle is-6">{{ post.excerpt }}</p>
                  </div>
                </div>
              </div>
              <footer class="card-footer">
                <a rel="noopener" target="_blank" class="card-footer-item" v-bind:href="post.url">Zobacz post</a>
              </footer>
            </div>
          </div>
        </div>
        <div class="buttons is-centered">
          <a class="button is-primary" href="https://blog.kaczkoland.pl" target="_blank" rel="noopener">Zobacz więcej</a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios';
// import Stat from "@/components/Stat";

export default {
  name: 'Home',
  // components: {
  //   Stat
  // },
  async created() {
    // const topPlayers = await getTopPlayers();
    //
    // if(topPlayers.success) {
    //   this.
    // }

    axios.get("https://blog.kaczkoland.pl/ghost/api/v3/content/posts/?key=" + this.blogKey).then(results => {
      this.posts = results.data.posts;
    })
  }, data: function() {
    return {
      players: [],
      stats: [],
      error: false,
      blogKey: "bb5ed4ed8e2ed1d9b0628b3e02",
      posts: [],
      ranks: []
    }
  }, watch: {
    error : function (error) {
      if(error) {
        this.$buefy.snackbar.open({
          message: 'Wystąpił błąd podczas ładowania danych',
          type: 'is-danger',
          position: 'is-bottom',
          actionText: 'Przeładuj stronę',
          indefinite: true,
          onAction: () => {
            this.$router.go(this.$router.currentRoute)
          }
        })
      }
    }
  }

}
</script>

<style scoped>
.subtitle {
  margin-bottom: 10px;
}
.title {
  margin-top: 10px;
}

.is-black-color {
  color: black;
}
</style>
