<template>
  <div class="column is-4">
    <div class="box">
      <article class="media">
        <div class="media-left">
          <figure class="image is-64x64">
            <b-skeleton v-if="!item.username" :animated="true" width="64px" height="64px"></b-skeleton>
            <img v-bind:src="'https://mc-heads.net/avatar/' + item.username + '.png'" alt="Image" v-else>
          </figure>
        </div>
        <div class="media-content">
          <div class="content">
            <b-skeleton v-if="!item.username" :animated="true" width="50%"></b-skeleton>
            <p class="nickname" v-else><strong class="minecraft-font nickname">{{ item.username }}</strong> <small>@{{ item.social_media_name }}</small></p>
            <b-skeleton v-if="!item.description" :animated="true" width="75%"></b-skeleton>
            <p class="description" v-else>{{ item.description }}</p>
          </div>
          <nav class="level is-mobile">
            <div class="level-left">
              <a class="level-item" v-for="i2 in item.social_media" :key="i2">
                <a class="icon is-small clickable" v-bind:href="i2.link">
                    <i v-bind:class="'fab fa-' + i2.type" aria-hidden="true"></i>
                </a>
              </a>
            </div>
          </nav>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
export default {
  name: "StaffCard",
  props: {
    item: Object
  }
}
</script>

<style scoped>
.content {
  margin-bottom: 0;
}
.icon {
  color: #639fff;
}

.nickname {
  margin-bottom: 0 !important;
  color: white;
}

.description {
  margin: 0;
  color: white;
}
</style>
