<template>
  <div class="column is-one-quarter">
    <div class="box">
        <div class="container">
          <article class="media">
            <div class="media-left">
              <figure class="image is-48x48">
                <img v-if="data.avatar" v-bind:src="data.avatar" alt="Avatar">
              </figure>
            </div>
            <div class="media-content">
              <p class="title is-4"><a v-bind:href="data.link" target="_blank">{{ data.title }}</a></p>
              <p class="subtitle is-6">{{ data.description }}</p>
            </div>
          </article>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppCard",
  props: {
    data: Object
  }
}
</script>

<style scoped>

</style>
