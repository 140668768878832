<template>
   <div class="hero is-fullheight" style="background-color: #3b3939;">
<!--     <div class="is-flex is-justify-content-center is-align-content-center" style="background-color: #3b3939; padding-top: 50px; height: 100%;">-->
      <div class="hero-body is-justify-content-center">
        <h1 class="title">Strona z karami została wyłączona!</h1>
      </div>

<!--     </div>-->
   </div>
</template>

<script>
export default {
  name: "KaryDisabled"
}
</script>

<style>
body {
  min-height: 50vh;
}
</style>
