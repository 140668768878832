function getDistance(distance) {
    return Math.round(((distance/100000) + Number.EPSILON) * 100) / 100;
}

function getTimeOnline(time) {
    return Math.round(((time/20/60/60) + Number.EPSILON) * 100) / 100;
}

export default { getDistance, getTimeOnline, readableTime, readableShortTime, readableTimeObject }


function readableTime(time) {
    if(time === 0) return "-";

    const obj = readableTimeObject(time);

    return (obj.days !== 0 ? obj.days + "d " : "") + "" + (obj.hours !== 0 ? obj.hours + "h " : "") + "" + (obj.minutes !== 0 ? obj.minutes + "m" : "");

}

function readableTimeObject(time) {
    if(time === 0) return {days: 0, hours: 0, minutes: 0};

    const days = Math.floor(time / (1000  * 60 * 24));
    const hours = Math.floor((time % (1000 * 60 * 24)) / (1000 * 60));
    const minutes = Math.floor((time % (1000 * 60)) / (1000));

    return {days, minutes, hours};
}

function readableShortTime(time) {
    if(time === 0) return "-";

    const days = Math.floor(time / (1000  * 60 * 24));
    const hours = Math.floor((time % (1000 * 60 * 24)) / (1000 * 60));
    // const minutes = Math.floor((time % (1000 * 60)) / (1000));

    return (days !== 0 ? days + "d " : "") + "" + (hours !== 0 ? hours + "h " : "");

}
