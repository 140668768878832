<template>
  <section style="margin: 20px">
    <div class="container has-text-centered">
      <h1 class="title">Statystyki</h1>
      <h1 class="subtitle is-5">Sprawdź statystyki po nicku!</h1>
      <div class="field has-addons has-addons-centered">
        <p class="control">
          <b-autocomplete rounded
                          v-model="nickname"
                          @keydown.native.enter="loadUser"
                          @typing="getAsyncData"
                          :loading="isFetching"
                          :data="data"
                          placeholder="np. rkubapl"
                          clearable
                          @select="option => {nickname = option; loadUser()}">
            <template slot-scope="props">
              <div class="media" style="display: flex;align-items: center;justify-content: center;">
                <div class="media-left">
                  <img width="32" :src="'https://mc-heads.net/avatar/' + props.option ">
                </div>
                <div class="media-content is-vertical-center">
                  {{ props.option }}
                </div>
              </div>
            </template>
          </b-autocomplete>
        </p>
        <p class="control">
          <button class="button is-primary" :disabled="nickname === ''" v-on:click="loadUser">
            <span class="icon">
                <i class="fas fa-search"></i>
            </span>
<!--            <span>Szukaj</span>-->
          </button>
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import debounce from 'lodash/debounce'
import {getPlayers} from "@/api";

export default {
  name: "Search",
  data: function () {
    return {
      nickname: "",
      data: [],
      isFetching: false
    }
  }, methods: {
    loadUser() {
      if (this.nickname) window.open("/u/" + this.nickname);
    }, getAsyncData: debounce(function (playername) {
      if(!playername || playername < 3) {
        this.data = [];
        return;
      }

      this.isFetching = true
      getPlayers(playername).then(d => {
        if(d.success) this.data = d.data;
        else this.data = [];
      }).catch((error) => {
        this.data = [];
        throw error;
      }).finally(() => {
        this.isFetching = false
      })
    }, 1000)
  }
}
</script>
