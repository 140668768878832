<template>
  <div style="background-color: #3b3939;">
    <section class="section">
      <div class="container has-text-centered">
        <h1 class="title">Statystyki wszystkich graczy</h1>
        <div class="columns is-centered is-multiline">
<!--          <MainStatCard image="https://cdn.discordapp.com/attachments/677203941422792721/777496421103960104/180.png" title="Wykopane diamenty" v-bind:stat="all.minedDiamonds"></MainStatCard>-->
          <MainStatCard image="https://cdn.discordapp.com/attachments/677203941422792721/777497949953065000/150.png" title="Wykopane bloki" v-bind:stat="all.minedBlocks"></MainStatCard>
          <MainStatCard image="https://cdn.discordapp.com/attachments/677203941422792721/777498466489597972/150.png" title="Postawione bloki" v-bind:stat="all.placedBlocks"></MainStatCard>
<!--          <MainStatCard image="https://cdn.discordapp.com/attachments/677203941422792721/777501221870108712/150.png" title="Śmierci" v-bind:stat="all.deaths"></MainStatCard>-->
          <MainStatCard image="https://cdn.discordapp.com/attachments/677203941422792721/777502368165265438/150.png" title="Czas online" v-bind:stat="all.playedTime" type="s" :time="true"></MainStatCard>
          <MainStatCard image="https://cdn.discordapp.com/attachments/677203941422792721/777502729173073950/150.png" title="Przebyta odległość" v-bind:stat="all.walkedDistance" type=" km"></MainStatCard>
          <MainStatCard image="https://cdn.discordapp.com/attachments/677203941422792721/777544806620069899/150.png" title="Zabite moby" v-bind:stat="all.killedMobs"></MainStatCard>
          <MainStatCard image="https://cdn.discordapp.com/attachments/677203941422792721/778597632297271316/150.png" title="Zrobione itemy" v-bind:stat="all.craftedItems"></MainStatCard>
          <MainStatCard image="https://cdn.discordapp.com/attachments/677203941422792721/777858413538836520/150.png" title="Unikalnych graczy" v-bind:stat="all.totalUsers"></MainStatCard>
          <MainStatCard image="https://cdn.discordapp.com/attachments/589467515651096586/779664011334647818/160.png" title="Wirtualna waluta" v-bind:stat="all.money" type="$"></MainStatCard>
        </div>
      </div>
    </section>
    <Search></Search>
    <section class="section" style="padding: 20px">
      <div class="container has-text-centered">
        <h1 class="title">Topki</h1>
        <h1 class="subtitle">Najlepsi gracze w różnych kategoriach</h1>
      </div>
      <br>
      <div class="container">
        <div class="columns is-centered is-multiline">
          <Stat type="wykopanych bloków" v-bind:data="stats.minedBlocks"></Stat>
          <Stat type="postawionych bloków" v-bind:data="stats.placedBlocks"></Stat>
          <Stat type="zabitych mobów" v-bind:data="stats.killedMobs"></Stat>
          <Stat type="przebytych bloków" v-bind:data="stats.walkedDistance" unit=" km"></Stat>
          <Stat type="zrobionych rzeczy" v-bind:data="stats.craftedItems"></Stat>
          <Stat type="zabitych graczy" v-bind:data="stats.killedPlayers"></Stat>
          <Stat type="śmierci" v-bind:data="stats.allDeaths"></Stat>
          <Stat type="czasu online" :data="stats.playedTime" :time="true"></Stat>
          <Stat type="monet" :data="stats.money" unit="$"></Stat>

<!--          <Stat type="wykopanych diamentów" v-bind:data="stats.minedDiamonds"></Stat>-->
<!--          <Stat type="śmierci" v-bind:data="stats.allDeaths"></Stat>-->
<!--          <Stat type="zabójstw" v-bind:data="stats2.kills" name="player_kills" unit=" " v-bind:ranks="ranks" color="white"></Stat>-->
<!--          <Stat type="czasu online" v-bind:data="stats2.timeonline" name="online_time" unit="h" v-bind:ranks="ranks" color="white"></Stat>-->
<!--          <Stat type="odległości" v-bind:data="stats2.walkedcm" name="walked_cm" unit="km" v-bind:ranks="ranks" color="white"></Stat>-->
<!--          <Stat type="wykopanych bloków" v-bind:data="stats2.minedBlocks"  name="mined_blocks" unit=" " v-bind:ranks="ranks" color="white"></Stat>-->
<!--          <Stat type="postawionych bloków" v-bind:data="stats2.placedBlocks" name="placed_blocks" unit=" " v-bind:ranks="ranks" color="white"></Stat>-->
<!--          <Stat type="wykopanych diamentów" v-bind:data="stats2.minedDiamonds" name="mined_diamonds" unit=" " v-bind:ranks="ranks" color="white"></Stat>-->
<!--          <Stat type="zrobionych rzeczy" v-bind:data="stats2.craftedItems"  name="crafted_items" unit=" " v-bind:ranks="ranks" color="white"></Stat>-->
<!--          <Stat type="zabitych mobów" v-bind:data="stats2.mobKills" name="mob_kills" unit=" " v-bind:ranks="ranks" color="white"></Stat>-->
<!--          <Stat type="najbogatszych graczy" v-bind:data="stats2.money" name="money" unit="$" v-bind:ranks="ranks" color="white"></Stat>-->
        </div>
      </div>
      <br>
      <div class="container has-text-centered">
        <p class="has-text-centered" style="color: white">Statystyki nie są odświeżane na żywo i mogą nie być w 100% poprawne!</p>
      </div>
    </section>
    <Apps></Apps>
  </div>
</template>

<script>
import MainStatCard from "@/components/MainStatCard";
import Search from "@/components/Search";
import Stat from "@/components/Stat";
import Apps from "@/components/Apps";
import statsUtils from "@/statsUtils";
import {getSumData, getTopPlayers} from "@/api.js";

export default {
  name: "stats2",
  components: {
    Search,
    MainStatCard,
    Stat,
    Apps
  }, async created() {
    this.connection = new WebSocket("wss://api.kaczkoland.pl/v2/ws")

    const t = this;

    this.connection.onmessage = function(event) {
      console.log(JSON.parse(event.data))
      t.all = JSON.parse(event.data);
    }

    this.connection.onopen = function(event) {
      t.all = JSON.parse(event.data);
    }

    const sumData = await getSumData();

    if(sumData.success) {
      this.all = sumData.data;
    }

    const topPlayers = await getTopPlayers();

    if(sumData.success) {
      this.stats = topPlayers.data;
    }

  }, data : function() {
    return {
      stats: {},
      players: [],
      all: {},
      error: false,
      ranks: [],
      apps: [],
      connection: null
    }
  }, watch: {
    error : function (error) {
      if(error) {
        this.$buefy.snackbar.open({
          message: 'Wystąpił błąd podczas ładowania danych',
          type: 'is-danger',
          position: 'is-bottom',
          actionText: 'Przeładuj stronę',
          indefinite: true,
          onAction: () => {
            this.$router.go(this.$router.currentRoute)
          }
        })
      }
    }
  }, methods: {
    statsUtils
  }
}
</script>

<style scoped>
</style>
