<template>
  <article class="message">
    <div class="message-header clickable" v-on:click="visible = !visible">
      <b-skeleton v-if="!question" width="50%" :animated="true"></b-skeleton>
      <p>{{question}}</p>
      <span class="icon">
          <i class="fas fa-arrow-right"></i>
      </span>
    </div>
    <div class="message-body" v-if="visible && answer">
      <p v-html="answer"></p>
    </div>
  </article>
</template>

<script>
export default {
  name: "FaqCard",
  data : function () {
    return {
      visible: false
    }
  }, props: {
    question: String,
    answer: String
  }
}
</script>

<style scoped>
  .clickable{
    cursor:pointer;
    outline:none
  }
</style>